import {
  IonLabel,
  IonCheckbox,
  IonTextarea,
  IonItem,
  IonCard,
  IonCardContent,
  IonCol,
  IonSelect,
  IonSelectOption
} from "@ionic/react";
import React, { useEffect, useState } from "react";




const Textfield = ({
  setSubmitQuestion,
  setSelected,
  selected,
  setQuestionDetails,
  elementAndPageId,
}) => {

  const [size, setSize] = useState(
  elementAndPageId.e1.size ? elementAndPageId.e1.size : 1
  );

  useEffect(() => {
    if (selected === "textbox") {
      setSubmitQuestion(true);
      setQuestionDetails({ type: "textbox", size: size});
    } else {
      setSubmitQuestion(false);
    }

    console.log("CHANGE", size)
  }, [selected, setSubmitQuestion, setQuestionDetails, elementAndPageId, size]);


  

  return (
    <IonCol size={!selected ? 6 : selected === "textbox" ? 12 : null}>
      <div style={{ height: "235px", display: "flex" }}>
        <div>
          <IonCheckbox
            checked={selected === "textbox"}
            value={"textbox"}
            onIonChange={(e) => {
              e.detail.checked
                ? setSelected(e.detail.value)
                : setSelected(null);
            }}
          />
        </div>
        <IonCard style={{ width: "100%", margin: "0px 10px" }}>
          <IonItem>
            <IonLabel>Textfeld</IonLabel>
            <IonLabel><div stlye={{display: 'flex', flexDirection: 'column'}}>
              <input type="radio" name="textfieldSize" checked={ parseInt(size,10) === 1 } onClick={() => { setSize(1) }}/> 1 zeilig <input style={{marginLeft: '20px'}} type="radio"  name="textfieldSize"  checked={ parseInt(size,10) === 10 } onClick={() => { setSize(10) }}/> mehrzeilig</div></IonLabel>
          </IonItem>

          <IonCardContent>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  outline: "solid #0000005e 1px",
                  padding: "10px 0px",
                  margin: "10px 0px",
                  // minHeight: "50px"
                }}
              >
                <IonItem>
                  <IonTextarea                  
                  style={{textAlign: 'center'}}
                    rows="1"
                    cols="20"
                    value={null}
                    placeholder="Hier erscheint der eingegebene Text des Patienten"
                  ></IonTextarea>
                </IonItem>
              </div>
           
            </div>            
          </IonCardContent>
        </IonCard>
      </div>
    </IonCol>
  );
};

export default Textfield;
