import {
  IonRow,
  IonButton,
  IonItem,
  IonTextarea,
  IonCol,
  IonLabel,
  IonToggle,
  IonSelect,
  IonSelectOption,
  IonChip,
  IonIcon,
} from "@ionic/react";

import { close } from "ionicons/icons";
import React, { useState, useEffect } from "react";

import BinaryChoice from "../components/sub/BinaryChoice";
import Smileys from "../components/sub/Smileys"
import Numbers from "../components/sub/Numbers"
import Dropdown from "../components/sub/Dropdown";
import MultipleOptions from "../components/sub/MultipleOptions";
import RatingModule from "../components/sub/RatingModule";
import Textfield from "../components/sub/Textfield";
import NoResponse from "../components/sub/NoResponse";
import ListSingleSelect from "../components/sub/ListSingleSelect";

import "../app.css";

const EditPageQuestion = ({
  setElementAndPageId,
  elementAndPageId,
  currentSurveyId,
  setTriggerGet,
  setEditQuestionMode,
  credentials,
  currentPageElements,
}) => {
  const [questionText, setQuestionText] = useState(
    elementAndPageId.e1.question ? elementAndPageId.e1.question : null
  );
 
  const [submitQuestion, setSubmitQuestion] = useState();
  const [selected, setSelected] = useState(
    elementAndPageId.e1.type
      ? elementAndPageId.e1.type === "rating10" ||
        elementAndPageId.e1.type === "rating5"
        ? "RatingModule"
        : elementAndPageId.e1.type
      : null
  );
  const [conditionalQuestion, setConditionalQuestion] = useState(
    elementAndPageId.e1.displaycondition.questionid &&
      elementAndPageId.e1.displaycondition.questionid
      ? true
      : false
  );
  const [questionDetails, setQuestionDetails] = useState(null);
  const [setTextLinks] = useState("");
  const [setTextRechts] = useState("");
  const [unchecked, setUnchecked] = useState(false);
  const [checked, setChecked] = useState(
    elementAndPageId.e1.required && elementAndPageId.e1.required ? true : false
  );
  const [selectedQuestion, setSelectedQuestion] = useState(
    elementAndPageId.e1.displaycondition.questionid &&
      elementAndPageId.e1.displaycondition.questionid
      ? { questionId: elementAndPageId.e1.displaycondition.questionid }
      : null
  );
  const [selectedAnswer, setSelectedAnswer] = useState(
    elementAndPageId.e1.displaycondition.answers &&
      elementAndPageId.e1.displaycondition.answers
      ? elementAndPageId.e1.displaycondition.answers
      : null
  );

  const [excludedUsers, setExcludedUsers] = useState(
    elementAndPageId.e1.excludedUsers &&
      elementAndPageId.e1.excludedUsers.length > 0
      ? elementAndPageId.e1.excludedUsers
      : []
  );

  const [excludedUsersMode, setExcludedUsersMode] = useState(
    elementAndPageId.e1.excludedUsers &&
      elementAndPageId.e1.excludedUsers.length > 0
      ? true
      : false
  );

  const [triggerFetchUsers, setTriggerFetchUsers] = useState(true);
  const [userData, setUserData] = useState([]);


  console.log("currentPageElements", currentPageElements);

  let fetch_getUsers = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    // console.log('NIMM DIESE ID', client.id);

    var raw = JSON.stringify({});
    // console.log(raw);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.NODE_ENV === "production"
        ? `https://fb-backend.procuratio.com/admin/allusers/`
        : `http://localhost:3000/`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => setUserData(result))
      .catch((error) => console.log("error", error));

    setTriggerFetchUsers(false);
  };

  useEffect(() => {
    triggerFetchUsers && fetch_getUsers();
  }, [triggerFetchUsers]);

  useEffect(() => {
    console.log(userData);
  }, [userData]);

  const questionDetailSubmitHandler = async () => {

    console.log("q details", questionDetails.size)
    try {
      const initfetch = await fetch(
        process.env.NODE_ENV === "production"
          ? `https://fb-backend.procuratio.com/editsurvey/`
          : `http://localhost:3000/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            clientid: credentials,
            trigger: "questiondetailsubmit",
            input: {
              required: checked,
              ...(conditionalQuestion && {
                displaycondition: {
                  questionid: selectedQuestion.questionId,
                  answers: selectedAnswer,
                },
              }),
              ...(excludedUsers &&
                excludedUsers.length > 0 && {
                  excludedUsers,
                }),
              question: questionText,
              left: questionDetails.left,
              right: questionDetails.right,
              type: questionDetails.type,
              size: questionDetails.size,
              ...(questionDetails.choices && {
                choices: questionDetails.choices,
              }),
            },
            surveyid: currentSurveyId,
            pageid: elementAndPageId.pageid,
            elementid: elementAndPageId.e1._id,
          }),
        }
      );

      const data = await initfetch.json();

      console.log(data);
      setEditQuestionMode(false);
    } catch (err) {
      console.log(err);
    } finally {
      setTriggerGet(true);
    }
  };

  useEffect(() => console.log(selected), [selected]);

  const compareWith1 = (o1, o2) => {
    return o1 && o2 ? o1.questionId === o2.questionId : o1 === o2;
  };

  const customAlertOptions = {
    cssClass: "custom-button-class",
  };

  console.log(excludedUsers);

  return (
    <>
      <IonRow>
        <IonCol size="12">
          <IonItem lines="none">
            <IonLabel slot="end" style={{ flex: "none" }}>
              Pflichtfrage
            </IonLabel>
            <IonToggle
              slot="end"
              checked={checked}
              onIonChange={(e) => setChecked(e.detail.checked)}
            />
          </IonItem>
          <IonItem lines="none">
            <IonLabel slot="end" style={{ flex: "none" }}>
              Conditional Frage
            </IonLabel>
            <IonToggle
              slot="end"
              checked={conditionalQuestion}
              onIonChange={(e) => {
                setConditionalQuestion(e.detail.checked);
                if (e.detail.checked) {
                  setSelectedQuestion(null);
                  setSelectedAnswer(null);
                }
              }}
            />
          </IonItem>
          <IonItem lines="none">
            {excludedUsersMode && userData && (
              <IonSelect
                multiple
                className="selectCss"
                interfaceOptions={customAlertOptions}
                value={excludedUsers}
                placeholder="Auswählen"
                onIonChange={(e) => {
                  setExcludedUsers(e.detail.value);
                }}
              >
                {userData.map((e, ind) => (
                  <IonSelectOption key={ind}>{e.email}</IonSelectOption>
                ))}
              </IonSelect>
            )}
            <IonLabel slot="end" style={{ flex: "none" }}>
              Exclude Users
            </IonLabel>
            <IonToggle
              slot="end"
              checked={excludedUsersMode}
              onIonChange={(e) => {
                setExcludedUsersMode(e.detail.checked);
                if (!e.detail.checked) {
                  setExcludedUsers(null);
                }
              }}
            />
          </IonItem>
          {excludedUsers && excludedUsers.length > 0 && (
            <IonItem lines="none">
              {excludedUsers.map((user) => (
                <IonChip>
                  <IonLabel>{user}</IonLabel>
                  <IonIcon
                    onClick={() => {
                      setExcludedUsers(
                        excludedUsers.filter((email) => email !== user)
                      );
                    }}
                    icon={close}
                  />
                </IonChip>
              ))}
            </IonItem>
          )}
        </IonCol>

        {conditionalQuestion && (
          <>
            <IonCol size="12">
              <IonButton size="small" expand="full" fill="outline">
                Set Conditional Question
              </IonButton>
            </IonCol>
            <IonCol size="12">
              <IonItem style={{ width: "100%" }}>
                <>
                  <IonLabel>Bitte Frage auswählen</IonLabel>
                  <IonSelect
                    className="selectCss"
                    interfaceOptions={customAlertOptions}
                    compareWith={compareWith1}
                    value={selectedQuestion}
                    placeholder="Auswählen"
                    onIonChange={(e) => {
                      setSelectedQuestion(e.detail.value);
                    }}
                  >
                    {currentPageElements.elements
                      .filter(
                        (e) => e.type !== "textbox" || e.type !== "textblock"
                      )
                      .map((e, ind) => (
                        <IonSelectOption
                          key={ind}
                          value={{
                            questionTitle: e.title,
                            questionId: e._id,
                          }}
                        >
                          {e.title}
                        </IonSelectOption>
                      ))}
                  </IonSelect>
                </>
              </IonItem>
            </IonCol>
            {selectedQuestion !== null && (
              <IonCol size="12">
                <IonItem style={{ width: "100%" }}>
                  <IonLabel>Bitte Antort auswählen:</IonLabel>
                  <IonSelect
                    className="selectCss"
                    interfaceOptions={customAlertOptions}
                    multiple
                    value={selectedAnswer}
                    placeholder="Auswählen"
                    onIonChange={(e) => setSelectedAnswer(e.detail.value)}
                  >
                    {currentPageElements.elements.filter(
                      (elem) => elem._id === selectedQuestion.questionId
                    )[0].type === "boolean" ? (
                      <>
                        <IonSelectOption>{"ja"}</IonSelectOption>{" "}
                        <IonSelectOption>{"nein"}</IonSelectOption>{" "}
                      </>
                    ) : currentPageElements.elements.filter(
                        (elem) => elem._id === selectedQuestion.questionId
                      )[0].type === "dropdown" ||
                      currentPageElements.elements.filter(
                        (elem) => elem._id === selectedQuestion.questionId
                      )[0].type === "listsingleselect" ||
                      currentPageElements.elements.filter(
                        (elem) => elem._id === selectedQuestion.questionId
                      )[0].type === "checkbox" ? (
                      <>
                        {currentPageElements.elements
                          .filter(
                            (elem) => elem._id === selectedQuestion.questionId
                          )[0]
                          .choices.map((e, ind) => (
                            <IonSelectOption key={ind} value={e}>
                              {e}
                            </IonSelectOption>
                          ))}
                      </>
                    ) : currentPageElements.elements.filter(
                        (elem) => elem._id === selectedQuestion.questionId
                      )[0].type === "rating10" ? (
                      <>
                        <IonSelectOption>{"1"}</IonSelectOption>
                        <IonSelectOption>{"2"}</IonSelectOption>
                        <IonSelectOption>{"3"}</IonSelectOption>
                        <IonSelectOption>{"4"}</IonSelectOption>
                        <IonSelectOption>{"5"}</IonSelectOption>
                        <IonSelectOption>{"6"}</IonSelectOption>
                        <IonSelectOption>{"7"}</IonSelectOption>
                        <IonSelectOption>{"8"}</IonSelectOption>
                        <IonSelectOption>{"9"}</IonSelectOption>
                        <IonSelectOption>{"10"}</IonSelectOption>
                      </>
                    ): currentPageElements.elements.filter(
                      (elem) => elem._id === selectedQuestion.questionId
                    )[0].type === "nummern" ? (
                    <>
                      <IonSelectOption>{"1"}</IonSelectOption>
                      <IonSelectOption>{"2"}</IonSelectOption>
                      <IonSelectOption>{"3"}</IonSelectOption>
                      <IonSelectOption>{"4"}</IonSelectOption>
                      <IonSelectOption>{"5"}</IonSelectOption>
                      <IonSelectOption>{"6"}</IonSelectOption>
                      <IonSelectOption>{"7"}</IonSelectOption>
                      <IonSelectOption>{"8"}</IonSelectOption>
                      <IonSelectOption>{"9"}</IonSelectOption>
                      <IonSelectOption>{"10"}</IonSelectOption>
                    </>
                  )  : currentPageElements.elements.filter(
                        (elem) => elem._id === selectedQuestion.questionId
                      )[0].type === "rating5" ? (
                      <>
                        <IonSelectOption>{"1"}</IonSelectOption>
                        <IonSelectOption>{"2"}</IonSelectOption>
                        <IonSelectOption>{"3"}</IonSelectOption>
                        <IonSelectOption>{"4"}</IonSelectOption>
                        <IonSelectOption>{"5"}</IonSelectOption>
                      </>
                    ) : null}
                  </IonSelect>
                </IonItem>
              </IonCol>
            )}
            <IonCol size="12">
              <IonItem style={{ width: "100%" }}>
                <IonButton
                  style={{ marginLeft: "4px" }}
                  slot="end"
                  shape="round"
                  fill="outline"
                  onClick={() => {
                    setConditionalQuestion(null);
                    setSelectedQuestion(null);
                    setSelectedAnswer(null);
                  }}
                >
                  Abbrechen
                </IonButton>
              </IonItem>
            </IonCol>{" "}
          </>
        )}
        <IonCol>
          <IonButton size="small" expand="full" fill="outline">
            NEUE FRAGE / NEUEN TEXT EINGEBEN
          </IonButton>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonItem>
            <IonTextarea
              value={questionText}
              placeholder="Hier bitte Frage oder Infotext eingeben"
              onIonChange={(e) => setQuestionText(e.detail.value)}
            ></IonTextarea>
          </IonItem>
        </IonCol>
      </IonRow>
      {questionText && questionText.length && (
        <>
          <IonRow>
            <IonCol>
              <IonButton size="small" expand="full" fill="outline">
                Wie soll der Patient die Frage beantworten?
                {/* How should the user answer the question? */}
              </IonButton>
            </IonCol>
          </IonRow>
          <IonRow>
            {selected === null || selected === "boolean" ? (
              <BinaryChoice
                setSubmitQuestion={setSubmitQuestion}
                setSelected={setSelected}
                setQuestionDetails={setQuestionDetails}
                selected={selected}
              />
            ) : null}
            {selected === null || selected === "dropdown" ? (
              <Dropdown
                setUnchecked={setUnchecked}
                unchecked={unchecked}
                setElementAndPageId={setElementAndPageId}
                setSubmitQuestion={setSubmitQuestion}
                setSelected={setSelected}
                setQuestionDetails={setQuestionDetails}
                selected={selected}
                elementAndPageId={elementAndPageId}
              />
            ) : null}

            {selected === null || selected === "textbox" ? (
              <Textfield
                setSubmitQuestion={setSubmitQuestion}
                setSelected={setSelected}
                setQuestionDetails={setQuestionDetails}
                selected={selected}
                elementAndPageId={elementAndPageId}
              />
            ) : null}
            {selected === null || selected === "RatingModule" ? (
              <RatingModule
                setSubmitQuestion={setSubmitQuestion}
                setSelected={setSelected}
                setQuestionDetails={setQuestionDetails}
                setTextLinks={setTextLinks}
                setTextRechts={setTextRechts}
                selected={selected}

                elementAndPageId={elementAndPageId}
              />
            ) : null}

{selected === null || selected === "nummern" ? (
              <Numbers
                setSubmitQuestion={setSubmitQuestion}
                setSelected={setSelected}
                setQuestionDetails={setQuestionDetails}
                selected={selected}
                elementAndPageId={elementAndPageId}
              />
            ) : null}

            {/* {selected === null || selected === "smileys" ? (
              <Smileys
                setSubmitQuestion={setSubmitQuestion}
                setSelected={setSelected}
                setQuestionDetails={setQuestionDetails}
                selected={selected}
              />
            ) : null} */}

            {selected === null || selected === "checkbox" ? (
              <MultipleOptions
                setSubmitQuestion={setSubmitQuestion}
                setSelected={setSelected}
                setQuestionDetails={setQuestionDetails}
                selected={selected}
                elementAndPageId={elementAndPageId}
              />
            ) : null}
            {selected === null || selected === "textblock" ? (
              <NoResponse
                setSubmitQuestion={setSubmitQuestion}
                setSelected={setSelected}
                setQuestionDetails={setQuestionDetails}
                selected={selected}
              />
            ) : null}
            {selected === null || selected === "listsingleselect" ? (
              <ListSingleSelect
                setUnchecked={setUnchecked}
                unchecked={unchecked}
                setSubmitQuestion={setSubmitQuestion}
                setSelected={setSelected}
                setQuestionDetails={setQuestionDetails}
                selected={selected}
                setElementAndPageId={setElementAndPageId}
                elementAndPageId={elementAndPageId}
              />
            ) : null}
          </IonRow>
        </>
      )}
      {submitQuestion && (
        <IonRow>
          <IonCol>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <IonButton
                disabled={conditionalQuestion && !selectedAnswer ? true : false}
                onClick={questionDetailSubmitHandler}
                style={{ width: "200px", paddingRight: "25px" }}
              >
                Speichern
              </IonButton>
            </div>
          </IonCol>
        </IonRow>
      )}
    </>
  );
};

export default EditPageQuestion;
