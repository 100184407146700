import {
  IonContent,
  IonToolbar,
  IonRow,
  IonGrid,
  IonCol,
  IonButton,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonCard,
  IonIcon,
  IonChip,
  IonPage,
  IonHeader,
  IonButtons,
  IonMenuButton,
  IonTitle,
  IonSegment,
  IonSegmentButton,
  IonToggle,
} from '@ionic/react';

import domtoimage from 'dom-to-image';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';

import jsPDF from 'jspdf';

import React, { useState, useEffect, createRef, useCallback, useRef } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

import { DateTime } from 'luxon';

import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
import { Pie } from 'react-chartjs-2';

import Cookies from 'universal-cookie';

import { saveAs } from 'file-saver';

import { warning } from 'ionicons/icons';
import { backspace } from 'ionicons/icons';
import { closeCircle } from 'ionicons/icons';

import './StatisticsOverview.css';
import { isNamedExportBindings } from 'typescript';

const ref = createRef();
const dt = DateTime.local();
const jsonexport = require('jsonexport');

Chart.plugins.unregister(ChartDataLabels);

const useStyles = makeStyles({
  root: {
    maxWidth: 300,
  },
  media: {
    height: 140,
  },
  textField: {
    display: 'inlineBlock',
    minWidth: 150,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
});

const StatisticsOverview = ({ credentials }) => {
  const [daten, setDaten] = useState(0);
  const [month, setMonth] = useState(false);
  const [filteredAnswers, setFilteredAnswers] = useState('');
  const [filterSet, setFilterSet] = useState(false);
  const [filterOption, setFilterOption] = useState(false);
  const [printing, setPrinting] = useState(false);
  const [surveyData, setSurveyData] = useState(false);
  const [alleDaten, setAlleDaten] = useState(false);
  const [surveyList, setSurveyList] = useState(false);
  // const [chooseClient, setChooseClient] = useState(null);
  const [chooseSurvey, setChooseSurvey] = useState(null);
  const [JSONdata, setJSONdata] = useState(null);
  const [pwd, setPwd] = useState(null);
  const [warningA, setWarningA] = useState(false);
  const [warningB, setWarningB] = useState(false);
  const [triggerDeleteResponses, setTriggerDeleteResponses] = useState(false);
  // const [showCampus, setShowCampus] = useState(true);
  const [surveyDataBackup, setSurveyDataBackup] = useState(false);
  const [pwdError, setPasswordError] = useState(false);
  const [deep, setDeep] = useState([]);
  const [deepSteps, setDeepSteps] = useState([]);
  const [highlight, setHighlight] = useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [currentPageMax, setCurrentPageMax] = useState(false);
  const [highlightComplete, setHighlightComplete] = React.useState(false);
  const [filterCompleteOption, setFilterCompleteOption] = useState(false);
  const [filterCompleteSet, setFilterCompleteSet] = useState(false);
  const [dataForCSV, setDataForCSV] = useState(false);

  const itemsRef = useRef([]);
  itemsRef.current = [];

  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const addToRefs = el => {
    if (el && el.innerHTML !== '') {
      itemsRef.current.push(el);
    }
  };

  const handleClickOpen1 = () => {
    setWarningA(true);
  };

  const handleClickOpen2 = () => {
    setWarningA(false);
    setWarningB(true);
  };

  const pieFilterOptions = (ques, opt) => {
    let filterColors = [
      '#cdffa5',
      '#e8fc8f',
      '#a3ffda',
      '#fcd1c2',
      '#f98f7a',
      '#89f4d2',
      '#f9e5a9',
      '#8b90e5',
      '#ffdaa3',
      '#f7b0a5',
      '#ffd2b7',
      '#fffdb7',
      '#bde3f9',
      '#9d96ff',
      '#f7b16f',
      '#d3aaf7',
      '#bdcaf9',
      '#ffc1d6',
      '#c2cbfc',
      '#bccdff',
      '#f7aae6',
      '#e3ff77',
      '#aaffb3',
      '#e4ffaf',
      '#bbc7f7',
      '#99fcd3',
      '#6bcaea',
      '#f29e93',
      '#ffcfcc',
      '#d9aefc',
      '#a5ffdb',
      '#8477e5',
      '#f2c9ff',
      '#8369e0',
      '#a8acff',
      '#a1fcc4',
      '#80ddf2',
      '#ff82c0',
      '#f9e9a2',
      '#91f7c1',
      '#efe375',
      '#b39eed',
      '#b0fc80',
      '#f4c097',
      '#f977ef',
      '#c9dcff',
      '#b1abf2',
      '#8ac9fc',
      '#ffbae8',
      '#c2a1ed',
      '#d4f48d',
      '#b2f780',
      '#c7ffaf',
      '#f9e9bb',
      '#c4a1fc',
      '#aeff96',
      '#b383e2',
      '#f26fde',
      '#b0fc7e',
      '#ffe4bc',
      '#fcd49c',
      '#ae9ffc',
      '#94f785',
      '#d5fc7b',
      '#a2f9cf',
      '#bdd8f9',
      '#a7dcef',
      '#f9f57f',
      '#9ff9ae',
      '#c2fc9f',
      '#87cced',
      '#afff9b',
      '#75daf9',
      '#f986cb',
      '#a5f984',
      '#f99c8e',
      '#ffbffd',
      '#f27dc3',
      '#fcbdcf',
      '#719ffc',
      '#72f9d1',
      '#f29be5',
      '#d9ffaa',
      '#6fb8ed',
      '#e6ffb2',
      '#70efb2',
      '#e6bffc',
      '#bdffad',
      '#8ff7f0',
      '#79c1f2',
      '#ffc7af',
      '#f9adff',
      '#9d90f4',
      '#9df9a0',
      '#f4a8d2',
      '#82bfe5',
      '#d3ffaf',
      '#8ec5ed',
      '#f4796e',
      '#7bfcb9 #d9f293',
      '#a8ffa9',
      '#7ee7ea',
      '#fcffaf',
      '#adb8ff',
      '#cbbdf9',
      '#99f78f',
      '#97f274',
      '#b9f99a',
      '#80f2ad',
      '#fff3cc',
      '#e9b4f7',
      '#afffcc',
      '#aeed82',
      '#80e5e5',
      '#9accf9',
      '#f477e4',
      '#ffe791',
      '#a4fc97',
      '#7df299',
      '#fcd7b0',
      '#f9b8e8',
      '#89f9bc',
      '#f6b6f9',
      '#85fc83',
      '#c7e8fc',
      '#e98bf9',
      '#99ed80',
      '#ef92bf',
      '#88ef99',
      '#ffd9c9',
      '#ffcccf',
      '#84f4a4',
      '#f7b7e9',
      '#9dfc94',
      '#f4c97f',
      '#b38fea',
      '#f7bb8c',
      '#f1f9a4',
      '#ef70e7',
      '#a4fcf0',
      '#d8fc9f',
      '#f4b07c',
      '#e4f49a',
      '#f7c6af',
      '#81ff7c',
      '#89e5e5',
      '#868def',
      '#bbf1f9',
      '#a2e5ef',
      '#a4f9a9',
      '#f29785',
      '#b278e2',
      '#fcb3c4',
      '#e2f492',
      '#d5bbf9',
      '#9cfcac',
      '#b4ffad',
      '#94e86a',
      '#c372e5',
      '#94f79f',
      '#d9ff9e',
      '#f28295',
      '#8176e8',
      '#ff75ea',
      '#9bf7d1',
      '#c7fca1',
      '#85ff77',
      '#93ef7c',
      '#f7f49e',
      '#f99c77',
      '#9a8def',
      '#a5f279',
      '#987fe2',
      '#ff99ed',
      '#f2ec7b',
      '#f489db',
      '#d09cf4',
      '#aaef6e',
      '#9ea1ff',
      '#f4a8c8',
      '#87f2c7',
      '#f7c7a3',
      '#7bf7c3',
      '#ab66d6',
      '#8dfca3',
      '#cf7cea',
      '#d6bbf7',
      '#f9d8a9',
      '#f788b6',
      '#76f2ee',
      '#f78390',
      '#856ff7',
      '#fc767f',
      '#b9c2f7',
      '#dff995',
      '#6ff2a5',
      '#a3e8ed',
      '#e1ff9b',
      '#f9c6ff',
    ];

    return Object.keys(opt).map((e, i) => {
      let jsonQuery = { question: ques, option: e, type: 'pie' };
      // console.log("piefilter", jsonQuery)

      return (
        <>
          <div onClick={() => checkDeepSteps(jsonQuery)} key={i} className="optionfields">
            <span className="box" style={{ backgroundColor: filterColors[i] }}></span>
            {e}
          </div>
        </>
      );
    });
  };

  const checkDeepSteps = jQu => {
    let double = deepSteps.some(e => e.question === jQu.question);
    // console.log(double)
    if (double === true) {
      console.log('DOPPELT');
      console.log(jQu.question);
    } else {
      console.log('NICHT DOPPELT');
      // setDeep(true);
      //  deepSteps && setDeepSteps([...deepSteps, jQu]);

      if (deepSteps.length === 0) {
        setDeep([jQu]);
        setDeepSteps([...deepSteps, jQu]);
        console.log('if empty dsteps', jQu);
      } else {
        setDeep([jQu]);
        setDeepSteps([...deepSteps, jQu]);
        console.log('else dsteps', jQu);
      }
    }

    // alert(`${jQu.question}\n${jQu.type}`)
  };

  const pbarFilterOptions = (ques, opt) => {
    let filterColors = [
      '#cdffa5',
      '#e8fc8f',
      '#a3ffda',
      '#fcd1c2',
      '#f98f7a',
      '#89f4d2',
      '#f9e5a9',
      '#8b90e5',
      '#ffdaa3',
      '#f7b0a5',
      '#ffd2b7',
      '#fffdb7',
      '#bde3f9',
      '#9d96ff',
      '#f7b16f',
      '#d3aaf7',
      '#bdcaf9',
      '#ffc1d6',
      '#c2cbfc',
      '#bccdff',
      '#f7aae6',
      '#e3ff77',
      '#aaffb3',
      '#e4ffaf',
      '#bbc7f7',
      '#99fcd3',
      '#6bcaea',
      '#f29e93',
      '#ffcfcc',
      '#d9aefc',
      '#a5ffdb',
      '#8477e5',
      '#f2c9ff',
      '#8369e0',
      '#a8acff',
      '#a1fcc4',
      '#80ddf2',
      '#ff82c0',
      '#f9e9a2',
      '#91f7c1',
      '#efe375',
      '#b39eed',
      '#b0fc80',
      '#f4c097',
      '#f977ef',
      '#c9dcff',
      '#b1abf2',
      '#8ac9fc',
      '#ffbae8',
      '#c2a1ed',
      '#d4f48d',
      '#b2f780',
      '#c7ffaf',
      '#f9e9bb',
      '#c4a1fc',
      '#aeff96',
      '#b383e2',
      '#f26fde',
      '#b0fc7e',
      '#ffe4bc',
      '#fcd49c',
      '#ae9ffc',
      '#94f785',
      '#d5fc7b',
      '#a2f9cf',
      '#bdd8f9',
      '#a7dcef',
      '#f9f57f',
      '#9ff9ae',
      '#c2fc9f',
      '#87cced',
      '#afff9b',
      '#75daf9',
      '#f986cb',
      '#a5f984',
      '#f99c8e',
      '#ffbffd',
      '#f27dc3',
      '#fcbdcf',
      '#719ffc',
      '#72f9d1',
      '#f29be5',
      '#d9ffaa',
      '#6fb8ed',
      '#e6ffb2',
      '#70efb2',
      '#e6bffc',
      '#bdffad',
      '#8ff7f0',
      '#79c1f2',
      '#ffc7af',
      '#f9adff',
      '#9d90f4',
      '#9df9a0',
      '#f4a8d2',
      '#82bfe5',
      '#d3ffaf',
      '#8ec5ed',
      '#f4796e',
      '#7bfcb9 #d9f293',
      '#a8ffa9',
      '#7ee7ea',
      '#fcffaf',
      '#adb8ff',
      '#cbbdf9',
      '#99f78f',
      '#97f274',
      '#b9f99a',
      '#80f2ad',
      '#fff3cc',
      '#e9b4f7',
      '#afffcc',
      '#aeed82',
      '#80e5e5',
      '#9accf9',
      '#f477e4',
      '#ffe791',
      '#a4fc97',
      '#7df299',
      '#fcd7b0',
      '#f9b8e8',
      '#89f9bc',
      '#f6b6f9',
      '#85fc83',
      '#c7e8fc',
      '#e98bf9',
      '#99ed80',
      '#ef92bf',
      '#88ef99',
      '#ffd9c9',
      '#ffcccf',
      '#84f4a4',
      '#f7b7e9',
      '#9dfc94',
      '#f4c97f',
      '#b38fea',
      '#f7bb8c',
      '#f1f9a4',
      '#ef70e7',
      '#a4fcf0',
      '#d8fc9f',
      '#f4b07c',
      '#e4f49a',
      '#f7c6af',
      '#81ff7c',
      '#89e5e5',
      '#868def',
      '#bbf1f9',
      '#a2e5ef',
      '#a4f9a9',
      '#f29785',
      '#b278e2',
      '#fcb3c4',
      '#e2f492',
      '#d5bbf9',
      '#9cfcac',
      '#b4ffad',
      '#94e86a',
      '#c372e5',
      '#94f79f',
      '#d9ff9e',
      '#f28295',
      '#8176e8',
      '#ff75ea',
      '#9bf7d1',
      '#c7fca1',
      '#85ff77',
      '#93ef7c',
      '#f7f49e',
      '#f99c77',
      '#9a8def',
      '#a5f279',
      '#987fe2',
      '#ff99ed',
      '#f2ec7b',
      '#f489db',
      '#d09cf4',
      '#aaef6e',
      '#9ea1ff',
      '#f4a8c8',
      '#87f2c7',
      '#f7c7a3',
      '#7bf7c3',
      '#ab66d6',
      '#8dfca3',
      '#cf7cea',
      '#d6bbf7',
      '#f9d8a9',
      '#f788b6',
      '#76f2ee',
      '#f78390',
      '#856ff7',
      '#fc767f',
      '#b9c2f7',
      '#dff995',
      '#6ff2a5',
      '#a3e8ed',
      '#e1ff9b',
      '#f9c6ff',
    ];

    // console.log("opt", opt);
    // console.log("ques", ques)
    return Object.keys(opt[0]).map((e, i) => {
      let jsonQuery = { question: ques, option: e, type: 'pbar' };
      // console.log(jsonQuery);
      return (
        <>
          <span onClick={() => checkDeepSteps(jsonQuery)} key={i} className="optionfields">
            <span className="box" style={{ backgroundColor: filterColors[i] }}></span> {e}
          </span>
        </>
      );
    });
  };

  const barFilterOptions = (ques, opt, skala) => {
    // console.log('opt', opt);
    // console.log('ques', ques);
    // console.log('skala', skala);

    // return opt.map((e, i) => {
    //   let jsonQuery = { question: ques, option: e, type: "bar" };
    //   // console.log("barfilter",jsonQuery)
    if (skala === 5) {
      return (
        <>
          {' '}
          <div className="optionfields subtext">
            <span className="box"></span>Bewertungen «« 3<span className="box"></span>Bewertungen »»
            3
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="subtext">
            <span className="optionfields">
              <span className="box" style={{ backgroundColor: '#a8a8a8' }}></span>
              Bewertungen «« 5
            </span>

            <span className="optionfields">
              <span className="box" style={{ backgroundColor: '#a8a8a8' }}></span>
              Bewertungen »» 5
            </span>
          </div>
        </>
      );
    }

    //   return (
    //     <span onClick={() => setDeep([jsonQuery])} className="optionfields">
    //       <span
    //         className="box"
    //         style={{ backgroundColor: "#a8a8a8" }}
    //       ></span>{" "}
    //       {e}
    //     </span>
    //   );
    // });
  };

  useEffect(() => {
    surveyData && console.log('filter complete is set');
    surveyData && console.log("SURVEY DATA", surveyData)

    // surveyData && surveyData.map(e => console.log(e));
  }, [filterCompleteSet, surveyData]);

  useEffect(() => {
    if (deep && surveyData) {
      let query = [];
      console.log(':: deep ::', deep);

      setSurveyData(false);

      deep.map(d => {
        surveyData.map(e => {
          console.log(e);
          e.surveyResponse.map(
            res =>
              res.responseData &&
              res.responseData.map(x => {
                // console.log(e)
                // console.log("d.question",d.question)
                // console.log("d.option",d.option)

                if (d.type === 'pie' || d.type === 'pbar' || d.type === 'listsingleselect') {
                  if (x.question === d.question && x.response === d.option) {
                    if (highlightComplete === 'complete') {
                      if (e.currentPage === currentPageMax) query.push(e);
                    } else if (highlightComplete === 'canceled') {
                      if (e.currentPage < currentPageMax) query.push(e);
                    } else query.push(e);
                  }
                }

                if (d.type === 'textbox') {
                  //  console.log("d",d);
                  if (x._id === d.res_id) {
                    if (highlightComplete === 'complete') {
                      if (e.currentPage === currentPageMax) {
                        console.log(e);
                        query.push(e);
                      }
                    } else if (highlightComplete === 'canceled') {
                      if (e.currentPage < currentPageMax) {
                        query.push(e);
                      }
                    } else query.push(e);
                  }
                  //  if (x.title === d.question && x.response === d.option ) {query.push(e)}
                }
                if (d.type === 'bar') {
                  if (x.pagetitle === d.question && x.title === d.option && d.response !== null) {
                    if (highlightComplete === 'complete') {
                      if (e.currentPage === currentPageMax) {
                        query.push(e);
                      }
                    } else if (highlightComplete === 'canceled') {
                      if (e.currentPage < currentPageMax) {
                        query.push(e);
                      }
                    } else query.push(e);
                  }
                }
              })
          );
        });
      });

      console.log('::qu:: normal', query);
      setSurveyData(query);
    }

    // setJSONdata(daten);
  }, [deep]);

  useEffect( () => { JSONdata && console.log(JSONdata)},[JSONdata] )

  const handleClose = () => {
    setTriggerDeleteResponses(false);
    setPasswordError(false);
    setWarningA(false);
    setWarningB(false);
  };

  const onChangePass = e => {
    setPwd(document.getElementById('loginP').value);
    setPasswordError(false);
  };

  const doBar = (labels, daten, skala, total, werte_gesamt, titel, stimmen) => {
    // console.log("::labels::", labels)
    // console.log("::daten::",daten)
    // let gesamt = daten.reduce((pv, cv) => console.log( pv + cv)) ;
    // let totaldurchschnitt = gesamt ;
    // console.log(":: STIMMEN ::", stimmen);
    let gesamtdurchschnitt = (werte_gesamt / labels.length).toFixed(2);

    if (werte_gesamt > 0) {
      return (
        <>
          <IonCol key="bar" size="10" className="ion-padding-start">
            <div className="toptext">
              <IonChip>{titel}</IonChip>
            </div>
            <Bar
              data={{
                // labels: Object.keys(prozent),
                labels: labels,
                datasets: [
                  {
                    label: 'Durchschnitt',
                    // data:  Object.values(prozent),
                    data: daten,
                    counts: stimmen,
                    backgroundColor: [
                      '#cdffa5',
                      '#e8fc8f',
                      '#a3ffda',
                      '#fcd1c2',
                      '#f98f7a',
                      '#89f4d2',
                      '#f9e5a9',
                      '#8b90e5',
                      '#ffdaa3',
                      '#f7b0a5',
                      '#ffd2b7',
                      '#fffdb7',
                      '#bde3f9',
                      '#9d96ff',
                      '#f7b16f',
                      '#d3aaf7',
                      '#bdcaf9',
                      '#ffc1d6',
                      '#c2cbfc',
                      '#bccdff',
                      '#f7aae6',
                      '#e3ff77',
                      '#aaffb3',
                      '#e4ffaf',
                      '#bbc7f7',
                      '#99fcd3',
                      '#6bcaea',
                      '#f29e93',
                      '#ffcfcc',
                      '#d9aefc',
                      '#a5ffdb',
                      '#8477e5',
                      '#f2c9ff',
                      '#8369e0',
                      '#a8acff',
                      '#a1fcc4',
                      '#80ddf2',
                      '#ff82c0',
                      '#f9e9a2',
                      '#91f7c1',
                      '#efe375',
                      '#b39eed',
                      '#b0fc80',
                      '#f4c097',
                      '#f977ef',
                      '#c9dcff',
                      '#b1abf2',
                      '#8ac9fc',
                      '#ffbae8',
                      '#c2a1ed',
                      '#d4f48d',
                      '#b2f780',
                      '#c7ffaf',
                      '#f9e9bb',
                      '#c4a1fc',
                      '#aeff96',
                      '#b383e2',
                      '#f26fde',
                      '#b0fc7e',
                      '#ffe4bc',
                      '#fcd49c',
                      '#ae9ffc',
                      '#94f785',
                      '#d5fc7b',
                      '#a2f9cf',
                      '#bdd8f9',
                      '#a7dcef',
                      '#f9f57f',
                      '#9ff9ae',
                      '#c2fc9f',
                      '#87cced',
                      '#afff9b',
                      '#75daf9',
                      '#f986cb',
                      '#a5f984',
                      '#f99c8e',
                      '#ffbffd',
                      '#f27dc3',
                      '#fcbdcf',
                      '#719ffc',
                      '#72f9d1',
                      '#f29be5',
                      '#d9ffaa',
                      '#6fb8ed',
                      '#e6ffb2',
                      '#70efb2',
                      '#e6bffc',
                      '#bdffad',
                      '#8ff7f0',
                      '#79c1f2',
                      '#ffc7af',
                      '#f9adff',
                      '#9d90f4',
                      '#9df9a0',
                      '#f4a8d2',
                      '#82bfe5',
                      '#d3ffaf',
                      '#8ec5ed',
                      '#f4796e',
                      '#7bfcb9 #d9f293',
                      '#a8ffa9',
                      '#7ee7ea',
                      '#fcffaf',
                      '#adb8ff',
                      '#cbbdf9',
                      '#99f78f',
                      '#97f274',
                      '#b9f99a',
                      '#80f2ad',
                      '#fff3cc',
                      '#e9b4f7',
                      '#afffcc',
                      '#aeed82',
                      '#80e5e5',
                      '#9accf9',
                      '#f477e4',
                      '#ffe791',
                      '#a4fc97',
                      '#7df299',
                      '#fcd7b0',
                      '#f9b8e8',
                      '#89f9bc',
                      '#f6b6f9',
                      '#85fc83',
                      '#c7e8fc',
                      '#e98bf9',
                      '#99ed80',
                      '#ef92bf',
                      '#88ef99',
                      '#ffd9c9',
                      '#ffcccf',
                      '#84f4a4',
                      '#f7b7e9',
                      '#9dfc94',
                      '#f4c97f',
                      '#b38fea',
                      '#f7bb8c',
                      '#f1f9a4',
                      '#ef70e7',
                      '#a4fcf0',
                      '#d8fc9f',
                      '#f4b07c',
                      '#e4f49a',
                      '#f7c6af',
                      '#81ff7c',
                      '#89e5e5',
                      '#868def',
                      '#bbf1f9',
                      '#a2e5ef',
                      '#a4f9a9',
                      '#f29785',
                      '#b278e2',
                      '#fcb3c4',
                      '#e2f492',
                      '#d5bbf9',
                      '#9cfcac',
                      '#b4ffad',
                      '#94e86a',
                      '#c372e5',
                      '#94f79f',
                      '#d9ff9e',
                      '#f28295',
                      '#8176e8',
                      '#ff75ea',
                      '#9bf7d1',
                      '#c7fca1',
                      '#85ff77',
                      '#93ef7c',
                      '#f7f49e',
                      '#f99c77',
                      '#9a8def',
                      '#a5f279',
                      '#987fe2',
                      '#ff99ed',
                      '#f2ec7b',
                      '#f489db',
                      '#d09cf4',
                      '#aaef6e',
                      '#9ea1ff',
                      '#f4a8c8',
                      '#87f2c7',
                      '#f7c7a3',
                      '#7bf7c3',
                      '#ab66d6',
                      '#8dfca3',
                      '#cf7cea',
                      '#d6bbf7',
                      '#f9d8a9',
                      '#f788b6',
                      '#76f2ee',
                      '#f78390',
                      '#856ff7',
                      '#fc767f',
                      '#b9c2f7',
                      '#dff995',
                      '#6ff2a5',
                      '#a3e8ed',
                      '#e1ff9b',
                      '#f9c6ff',
                    ],
                  },
                ],
              }}
              width={500}
              options={{
                tooltips: {
                  callbacks: {
                    label: function (tooltipItem, data) {
                      var label = data.datasets[tooltipItem.datasetIndex].label || '';
                      let endung;
                      if (data.datasets[tooltipItem.datasetIndex].counts[tooltipItem.index] === 1) {
                        endung = ' Teilnehmer';
                      } else {
                        endung = ' Teilnehmer';
                      }
                      if (label) {
                        label += ': ';
                      }

                      label +=
                        data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] +
                        ' (' +
                        data.datasets[tooltipItem.datasetIndex].counts[tooltipItem.index] +
                        endung +
                        ')';

                      return label;
                    },
                  },
                },

                responsive: true,
                legend: { display: false },
                scales: {
                  yAxes: [
                    {
                      ticks: {
                        min: 0,
                        max: skala,
                        callback: function (value) {
                          if (value === 0) {
                            return 'schlecht  ' + value;
                          }
                          if (value === skala) {
                            return 'perfekt  ' + value;
                          } else {
                            return value;
                          }
                        },
                      },
                      scaleLabel: {
                        display: true,
                        labelString: 'Bewertung',
                      },
                    },
                  ],
                },
              }}
            />{' '}
            <div>{barFilterOptions(titel, labels, skala)}</div>
            <div className="subtext">
              &#8709; aller Bewertungen : {gesamtdurchschnitt} | Teilnehmer : {total}
            </div>
          </IonCol>
        </>
      );
    } else {
      <IonCol></IonCol>;
    }
  };

  const doPieBar = (labels, daten, titel, stimmen, ids) => {
    // console.log('IDS2', ids);
    // console.log('VL0', Object.values(labels[0]));
    // console.log('VL', labels[0]);
    // console.log("DATEN2",daten)
    // console.log("LABELS",labels)
    // console.log(labels[0])
    return (
      <IonCol key="bar" size="12" className="ion-padding-start">
        <div className="toptext">
          <IonChip>{titel}</IonChip>
        </div>
        <Bar
          data={{
            labels: Object.keys(daten[0]),
            // labels: labels,
            datasets: [
              {
                label: 'Durchschnitt',
                // data:  Object.values(prozent),
                data: Object.values(daten[0]),
                absolut: Object.values(labels[0]),
                ids: ids,

                backgroundColor: [
                  '#cdffa5',
                  '#e8fc8f',
                  '#a3ffda',
                  '#fcd1c2',
                  '#f98f7a',
                  '#89f4d2',
                  '#f9e5a9',
                  '#8b90e5',
                  '#ffdaa3',
                  '#f7b0a5',
                  '#ffd2b7',
                  '#fffdb7',
                  '#bde3f9',
                  '#9d96ff',
                  '#f7b16f',
                  '#d3aaf7',
                  '#bdcaf9',
                  '#ffc1d6',
                  '#c2cbfc',
                  '#bccdff',
                  '#f7aae6',
                  '#e3ff77',
                  '#aaffb3',
                  '#e4ffaf',
                  '#bbc7f7',
                  '#99fcd3',
                  '#6bcaea',
                  '#f29e93',
                  '#ffcfcc',
                  '#d9aefc',
                  '#a5ffdb',
                  '#8477e5',
                  '#f2c9ff',
                  '#8369e0',
                  '#a8acff',
                  '#a1fcc4',
                  '#80ddf2',
                  '#ff82c0',
                  '#f9e9a2',
                  '#91f7c1',
                  '#efe375',
                  '#b39eed',
                  '#b0fc80',
                  '#f4c097',
                  '#f977ef',
                  '#c9dcff',
                  '#b1abf2',
                  '#8ac9fc',
                  '#ffbae8',
                  '#c2a1ed',
                  '#d4f48d',
                  '#b2f780',
                  '#c7ffaf',
                  '#f9e9bb',
                  '#c4a1fc',
                  '#aeff96',
                  '#b383e2',
                  '#f26fde',
                  '#b0fc7e',
                  '#ffe4bc',
                  '#fcd49c',
                  '#ae9ffc',
                  '#94f785',
                  '#d5fc7b',
                  '#a2f9cf',
                  '#bdd8f9',
                  '#a7dcef',
                  '#f9f57f',
                  '#9ff9ae',
                  '#c2fc9f',
                  '#87cced',
                  '#afff9b',
                  '#75daf9',
                  '#f986cb',
                  '#a5f984',
                  '#f99c8e',
                  '#ffbffd',
                  '#f27dc3',
                  '#fcbdcf',
                  '#719ffc',
                  '#72f9d1',
                  '#f29be5',
                  '#d9ffaa',
                  '#6fb8ed',
                  '#e6ffb2',
                  '#70efb2',
                  '#e6bffc',
                  '#bdffad',
                  '#8ff7f0',
                  '#79c1f2',
                  '#ffc7af',
                  '#f9adff',
                  '#9d90f4',
                  '#9df9a0',
                  '#f4a8d2',
                  '#82bfe5',
                  '#d3ffaf',
                  '#8ec5ed',
                  '#f4796e',
                  '#7bfcb9 #d9f293',
                  '#a8ffa9',
                  '#7ee7ea',
                  '#fcffaf',
                  '#adb8ff',
                  '#cbbdf9',
                  '#99f78f',
                  '#97f274',
                  '#b9f99a',
                  '#80f2ad',
                  '#fff3cc',
                  '#e9b4f7',
                  '#afffcc',
                  '#aeed82',
                  '#80e5e5',
                  '#9accf9',
                  '#f477e4',
                  '#ffe791',
                  '#a4fc97',
                  '#7df299',
                  '#fcd7b0',
                  '#f9b8e8',
                  '#89f9bc',
                  '#f6b6f9',
                  '#85fc83',
                  '#c7e8fc',
                  '#e98bf9',
                  '#99ed80',
                  '#ef92bf',
                  '#88ef99',
                  '#ffd9c9',
                  '#ffcccf',
                  '#84f4a4',
                  '#f7b7e9',
                  '#9dfc94',
                  '#f4c97f',
                  '#b38fea',
                  '#f7bb8c',
                  '#f1f9a4',
                  '#ef70e7',
                  '#a4fcf0',
                  '#d8fc9f',
                  '#f4b07c',
                  '#e4f49a',
                  '#f7c6af',
                  '#81ff7c',
                  '#89e5e5',
                  '#868def',
                  '#bbf1f9',
                  '#a2e5ef',
                  '#a4f9a9',
                  '#f29785',
                  '#b278e2',
                  '#fcb3c4',
                  '#e2f492',
                  '#d5bbf9',
                  '#9cfcac',
                  '#b4ffad',
                  '#94e86a',
                  '#c372e5',
                  '#94f79f',
                  '#d9ff9e',
                  '#f28295',
                  '#8176e8',
                  '#ff75ea',
                  '#9bf7d1',
                  '#c7fca1',
                  '#85ff77',
                  '#93ef7c',
                  '#f7f49e',
                  '#f99c77',
                  '#9a8def',
                  '#a5f279',
                  '#987fe2',
                  '#ff99ed',
                  '#f2ec7b',
                  '#f489db',
                  '#d09cf4',
                  '#aaef6e',
                  '#9ea1ff',
                  '#f4a8c8',
                  '#87f2c7',
                  '#f7c7a3',
                  '#7bf7c3',
                  '#ab66d6',
                  '#8dfca3',
                  '#cf7cea',
                  '#d6bbf7',
                  '#f9d8a9',
                  '#f788b6',
                  '#76f2ee',
                  '#f78390',
                  '#856ff7',
                  '#fc767f',
                  '#b9c2f7',
                  '#dff995',
                  '#6ff2a5',
                  '#a3e8ed',
                  '#e1ff9b',
                  '#f9c6ff',
                ],
              },
            ],
          }}
          width={500}
          options={{
            events: ['click', 'mousemove'],
            onClick: function (evt, element) {
              if (element.length > 0) {
                // console.log('ELEMENT', element[0]);
                // console.log('TYPE', element[0]._chart.config.type);
                // console.log('question', titel);
                // console.log('option', element[0]._chart.config.data.labels[element[0]._index]);
                // console.log('DAFQUQ', element[0]._chart.config.data);
                // console.log('ELEMENT', element[0]._index);
                // console.log('EVT', evt);
                let jsonQuery = {
                  question: titel,
                  option: element[0]._chart.config.data.labels[element[0]._index],
                  // type: element[0]._chart.config.type,
                  type: 'pie',
                };
                checkDeepSteps(jsonQuery);
              }
            },
            responsive: true,
            legend: { display: false },
            scales: {
              yAxes: [
                {
                  ticks: {
                    min: 0,
                  },
                  scaleLabel: {
                    display: true,
                    labelString: 'Prozent',
                  },
                },
              ],
            },
            tooltips: {
              function(context) {
                console.log('::context::', context);
              },
              callbacks: {
                label: (tooltipItems, data) => {
                  let endung;
                  if (data.datasets[tooltipItems.datasetIndex].absolut[tooltipItems.index] === 1) {
                    endung = ' Teilnehmer';
                  } else {
                    endung = ' Teilnehmer';
                  }
                  return (
                    data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index] +
                    ' %  (' +
                    data.datasets[tooltipItems.datasetIndex].absolut[tooltipItems.index] +
                    endung +
                    ')'
                  );
                },
              },
            },
          }}
        />
        {/* <div>{pbarFilterOptions(titel, labels)}</div> */}
        <div className="subtext">Teilnehmer : {stimmen}</div>
      </IonCol>
    );
  };

  const pdfToHTML = async () => {

  console.log("print")
  console.table(deepSteps)
  console.table(month)

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    
    var raw = JSON.stringify([
      {
        "data": JSONdata,
        "datumsbereich": month,
        "filter:": deepSteps
      }
    ]);
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw
    };
    
    fetch("http://saint.ategris.local/funktionen/api/pdf/pdf_print.php", requestOptions)
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.log('error', error));

    // setPrinting(true);
    // let pdf = new jsPDF('l', 'mm');
    // const pageWidth = pdf.internal.pageSize.getWidth();
    // const pageHeight = pdf.internal.pageSize.getHeight() - 10;
    // const pageRatio = pageWidth / pageHeight;

    // for (let i = 0; i <= itemsRef.current.length; i++) {
    //   if (i !== itemsRef.current.length) {
    //     const blob = await domtoimage.toPng(itemsRef.current[i], {
    //       bgcolor: '#FFFFFF',
    //     });

    //     const imgProps = pdf.getImageProperties(blob);
    //     const imgWidth = imgProps.width;
    //     const imgHeight = imgProps.height;
    //     const imgRatio = imgWidth / imgHeight;
    //     if (i > 0) {
    //       pdf.addPage();
    //     }
    //     pdf.setPage(i + 1);
    //     if (imgRatio >= 1) {
    //       const wc = imgWidth / pageWidth;
    //       if (imgRatio >= pageRatio) {
    //         pdf.addImage(
    //           blob,
    //           'PNG',
    //           0,
    //           (pageHeight - imgHeight / wc) / 2,
    //           pageWidth,
    //           imgHeight / wc,
    //           null,
    //           'NONE'
    //         );
    //       } else {
    //         const pi = pageRatio / imgRatio;
    //         pdf.addImage(
    //           blob,
    //           'PNG',
    //           (pageWidth - pageWidth / pi) / 2,
    //           0,
    //           pageWidth / pi,
    //           imgHeight / pi / wc,
    //           null,
    //           'NONE'
    //         );
    //       }
    //     } else {
    //       const wc = imgWidth / pageHeight;
    //       if (1 / imgRatio > pageRatio) {
    //         const ip = 1 / imgRatio / pageRatio;
    //         const margin = (pageHeight - imgHeight / ip / wc) / 4;
    //         pdf.addImage(
    //           blob,
    //           'PNG',
    //           (pageWidth - imgHeight / ip / wc) / 2,
    //           -(imgHeight / ip / wc + margin),
    //           pageHeight / ip,
    //           imgHeight / ip / wc,
    //           null,
    //           'NONE',
    //           -90
    //         );
    //       } else {
    //         pdf.addImage(
    //           blob,
    //           'PNG',
    //           (pageWidth - imgHeight / wc) / 2,
    //           -(imgHeight / wc),
    //           pageHeight,
    //           imgHeight / wc,
    //           null,
    //           'NONE',
    //           -90
    //         );
    //       }
    //     }

    //     let today = new Date();
    //     const dd = String(today.getDate()).padStart(2, '0');
    //     const mm = String(today.getMonth() + 1).padStart(2, '0');
    //     const yyyy = today.getFullYear();
    //     today = dd + '.' + mm + '.' + yyyy;
    //     pdf.setFontSize(20);
    //     pdf.setTextColor(40);
    //     pdf.setFontStyle('normal');
    //     pdf.setFontSize(10);
    //     pdf.text(`Seite ${String(i + 1)}`, 10, pdf.internal.pageSize.height - 3);
    //     pdf.text(
    //       `Datum: ${today}`,
    //       pdf.internal.pageSize.getWidth() / 2,
    //       pdf.internal.pageSize.height - 3,
    //       {
    //         align: 'center',
    //       }
    //     );

    //     const getFriendlyDate = (von, bis) => {
    //       const dateObjectFrom = new Date(von);
    //       const dateObjectTo = new Date(bis);

    //       const options = {
    //         year: 'numeric',
    //         month: '2-digit',
    //         day: 'numeric',
    //       };

    //       const monthfrom = dateObjectFrom.toLocaleString('de-DE', options);
    //       const monthto = dateObjectTo.toLocaleString('de-DE', options);
    //       return `Filterbereich: ${monthfrom} bis ${monthto}`;
    //     };

    //     if (month) {
    //       pdf.text(
    //         getFriendlyDate(month.start, month.end),
    //         pdf.internal.pageSize.width - 10,
    //         pdf.internal.pageSize.height - 3,
    //         {
    //           align: 'right',
    //         }
    //       );
    //     }
    //   } else {
    //     pdf.save('statistics.pdf');
    //     setPrinting(false);
    //   }
    // }
  };

  const getData = useCallback(async () => {
    try {
      const cookies = new Cookies();

      const initfetch = await fetch(
        process.env.NODE_ENV === 'production'
          ? `https://fb-backend.procuratio.com/admin/getadminsurvey/`
          : `http://localhost:3000/`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            admincookie: cookies.get('admincookie'),
          }),
        }
      );

      const data = await initfetch.json();

      let survey_list = [];

      data.map(e => survey_list.push(e.surveyId));
      let shortened_survey_list = Array.from(new Set(survey_list));
      console.log('shortened survey_list', shortened_survey_list);

      let survey_names = [];
      shortened_survey_list.map(e => survey_names.push(data.find(x => x.surveyId === e)));
      console.log('survey_names', survey_names);
      // setDataForCSV(survey_names);

      let surveys_json = [];
      for (let i = 0; i < shortened_survey_list.length; i++) {
        surveys_json.push({
          name: survey_names[i].surveyName,
          id: shortened_survey_list[i],
        });
      }
      console.log('::surveys_json::', surveys_json);
      setSurveyList(surveys_json);
      // setSurveyData(data);
      setAlleDaten(data);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {}, [surveyList]);

  useEffect(() => {
    console.log('DEEPSTEPS', deepSteps);
  }, [deepSteps]);

  useEffect(() => {
    triggerDeleteResponses && chooseSurvey && del_Responses(chooseSurvey.id);
  }, [triggerDeleteResponses]);

  const removeDeepSteps = step_id => {
    setSurveyData(false);

    // console.log("entferne id " + step_id + " " + deepSteps[step_id])
    // console.log("tempdaten4 vor löschen", deepSteps.length)

    let temp = [];

    deepSteps &&
      deepSteps.map((e, index) => {
        // console.log("index", index)

        if (index !== step_id) {
          temp.push(e);
        }
      });
    setDeepSteps(temp);

    // console.log("ALLEDATEN",alleDaten)

    let tempDaten = alleDaten.filter(e => e.surveyName === chooseSurvey.name);
    // console.log("tempdaten", tempDaten)
    // tempDaten=tempDaten.filter(e=>e.surveyName === chooseSurvey.name)
    // console.log("tempdaten2", tempDaten)
    // console.log("tempdaten3 nach löschen", temp.length)
    // console.log("tempDaten3", chooseSurvey.name)

    if (temp.length > 0) {
      let newArr = [];
      // console.log(":: deep ::", deep)

      temp.map(d => {
        // FILTER OPTIONS
        tempDaten.map(e => {
          // HOLDS ALL DATA
          e.surveyResponse.map(
            res =>
              res.responseData &&
              res.responseData.map(x => {
                if (d.type === 'pie' || d.type === 'pbar' || d.type === 'listsingleselect') {
                  if (
                    x.question === d.question &&
                    x.response === d.option &&
                    JSON.stringify(newArr).includes(x._id) === false
                  ) {
                    newArr.push(e);
                  }
                }

                if (d.type === 'textbox') {
                  //  console.log("d",d);
                  if (
                    x.title === d.question &&
                    x.response === d.option &&
                    JSON.stringify(newArr).includes(x._id) === false
                  ) {
                    newArr.push(e);
                  }
                }
                if (d.type === 'bar') {
                  if (
                    x.pagetitle === d.question &&
                    x.title === d.option &&
                    d.response !== null &&
                    JSON.stringify(newArr).includes(x._id) === false
                  ) {
                    newArr.push(e);
                  }
                }
              })
          );
        });
      });

      // alert("juuup")

      console.log('::qu::', newArr);
      setSurveyData(newArr);
    } else {
      let tempDaten = alleDaten.filter(e => e.surveyName === chooseSurvey.name);
      setSurveyData(tempDaten);
    }
  };

  const del_Responses = useCallback(async () => {
    try {
      const cookies = new Cookies();

      const initfetch = await fetch(
        process.env.NODE_ENV === 'production'
          ? `https://fb-backend.procuratio.com/admin/deleteresponses/`
          : `http://localhost:3000/`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            _id: chooseSurvey.id,
            pwd: pwd,
            admincookie: cookies.get('admincookie'),
          }),
        }
      );

      const data = await initfetch.json();
      if (data.result === 'Error') {
        setPasswordError(true);
      }
      if (data.result === 'OK') {
        setPasswordError(false);
        handleClose();
        setPwd(null);
        getData();
      }
    } catch (error) {
      console.log(error);
    }

    setTriggerDeleteResponses(false);
  });

  useEffect(() => {
    getData();
  }, [getData]);

  const reducer = (accumulator, currentValue) => accumulator + currentValue;

  const clearNull = e => {
    let filtered = e.filter(el => el !== null);
    return filtered;
  };

  useEffect(() => {
    if (surveyData) {
      console.log(surveyData);

      let pages = [];
      surveyData.forEach(e => {
        for (let i = 0; i < e.surveyResponse.length; i++) {
          pages.push(e.surveyResponse[i].title);
        }
      });

      pages = Array.from(new Set(pages));

      console.log(pages);

      let pageData = [];

      pages.filter((page, i) => {
        let Umfragen = [];

        surveyData.filter((e, index) =>
          e.surveyResponse.filter(
            res =>
              res.responseData &&
              res.responseData.filter(x => {
                if (x.pagetitle === page) {
                  Umfragen.push(x);
                }
              })
          )
        );

        let pie_labels = [];
        let pie_werte = [];
        let pie_alle = [];
        let monk_labels = [];
        let bar5_labels = [];
        let bar5_werte = [];
        let bar5_alle = [];
        let bar5_titel = [];
        let bar5_stimmen = [];
        let bar10_labels = [];
        let bar10_werte = [];
        let bar10_alle = [];
        let bar10_titel = [];
        let bar10_stimmen = [];
        let box_werte = [];
        let box_titel = [];
        let box_ids = [];

        Umfragen.map(e => {
          if (
            e.type === 'boolean' ||
            e.type === 'dropdown' ||
            e.type === 'checkbox' ||
            e.type === 'listsingleselect'
          ) {
            pie_labels.push(e.question);
            pie_werte.push(e.response);
            pie_alle.push(e);
          }

          if (e.type === 'rating5') {
            bar5_labels.push(e.title);
            bar5_werte.push(e.response);
            bar5_titel.push(e.pagetitle);
            bar5_alle.push(e);
          }

          if (e.type === 'rating10' || e.type === 'nummern') {
            bar10_labels.push(e.title);
            bar10_werte.push(e.response);
            bar10_titel.push(e.pagetitle);
            bar10_alle.push(e);
          }

          if (e.type === 'textbox' && e.response !== null && e.response !== '') {
            box_werte.push(e.response);
            box_titel.push(e.title);
            box_ids.push(e._id);
            // console.log("::BOXWERTE::", box_werte)
            // console.log("E RESPONSE",e.response)
          }
        });

        pie_labels = Array.from(new Set(pie_labels));

        let pie_daten = [];
        let pie_prozente = [];

        let monk_daten = [];
        let monk_prozente = [];
        // let monk_ids = [];

        pie_labels.filter((e, i) => {
          let y = [];
          // let ids = [];

          pie_alle.filter(x => {
            // console.log("XXXXX", x)
            if (x.question === e) {
              if (Array.isArray(x.response)) {
                x.response.forEach(e => {
                  if (x.response !== null) {
                    y.push(x.response);
                    // ids.push(x._id);
                  }
                });
              } else {
                if (x.response !== null) {
                  y.push(x.response);
                  // ids.push(x._id);
                }
              }
            }
          });
          let werte = {};
          let proz = {};
          // let idss = {};

          y.forEach(el => (werte[el] = 1 + (werte[el] || 0)));
          y.forEach(el => (proz[el] = Math.round((100 / y.length) * werte[el])));
          // ids.forEach(el => (idss[el] = 1 + (ids[el] || 0)));

          let durchschnitt = 0;
          if (y.length >= 2) {
            durchschnitt = y.reduce((prev, curr) => prev + curr) / y.length;
          } else {
            durchschnitt = y;
          }

          if (Object.keys(werte).length > 4) {
            // console.log("WERT Groesser 5 : " ,werte)

            monk_daten.push(werte);
            // console.log("array erstellt:",monk_daten)
            // console.log("nach clearnull:",clearNull(monk_daten))
            monk_labels.push(pie_labels[i]);
            monk_prozente.push(proz);
            // monk_ids.push(idss);
          } else {
            pie_daten.splice(i, 0, werte);
            pie_prozente.splice(i, 0, proz);
          }
        });

        bar5_labels = Array.from(new Set(bar5_labels));
        bar10_labels = Array.from(new Set(bar10_labels));

        let bar5_daten = [];
        bar5_labels.map((e, i) => {
          let y = [];
          bar5_alle.map(x => {
            if (x.title === e) {
              if (x.response !== null) {
                y.push(x.response);
              }
            }
          });
          let durchschnitt = (y.reduce((prev, curr) => prev + curr, 0) / y.length).toFixed(2);
          bar5_stimmen.push(y.length);
          bar5_daten.splice(i, 0, Number.parseFloat(durchschnitt).toFixed(1));
        });

        let bar10_daten = [];
        bar10_labels.map((e, i) => {
          let y = [];
          bar10_alle.map(x => {
            if (x.title === e) {
              if (x.response !== null) {
                y.push(x.response);
              }
            }
          });
          let durchschnitt;
          if (y.length > 0) {
            durchschnitt = (y.reduce((prev, curr) => prev + curr) / y.length).toFixed(2);
          } else {
            durchschnitt = 0;
          }
          bar10_stimmen.splice(i, 0, y.length);
          bar10_daten.splice(i, 0, durchschnitt);
        });

        let final = [];
        let pie_stimme = 0;

        if (pie_daten.length > 0 && pie_daten !== null) {
          // console.log(pie_daten)
          pie_daten = clearNull(pie_daten);
          // console.log("CLEARED",pie_daten)
          pie_labels = clearNull(pie_labels);
          pie_prozente = clearNull(pie_prozente);

          final.push({
            name: 'pie',
            inhalt: {
              labels: pie_labels,
              data: pie_daten,
              stimmen: pie_daten.map((e, i) => Object.values(pie_daten[i]).reduce(reducer, 0)),
              // stimmen: pie_daten.map((e,i) => {
              //   if (Object.values(pie_daten[i]).length > 1)
              //       {Object.values(pie_daten[i]).reduce(reducer)}
              //   else
              //       { Object.values(pie_daten[i]) }}
              //       ),
              prozente: pie_prozente,
              total: pie_werte.length,
            },
          });
        }

        if (monk_daten[0]) {
          // monk_daten=clearNull(monk_daten)

          // console.log('pbar length', monk_daten[0]);

          final.push({
            name: 'pbar',
            inhalt: {
              labels: monk_daten,
              data: monk_prozente,
              titel: monk_labels[0],
              stimmen: monk_daten.map((e, i) => Object.values(monk_daten[i]).reduce(reducer, 0)),
              // ids: monk_ids,
              total: pie_werte.length,
              // total: bar5_werte.length,
            },
          });

          // console.log(final)

          // console.log(JSONdata);
        }

        if (bar5_daten.length > 0 && bar5_daten !== null) {
          final.push({
            name: 'bar5',
            inhalt: {
              labels: bar5_labels,
              data: bar5_daten,
              titel: bar5_titel[0],
              stimmen: bar5_stimmen,
              total: bar5_stimmen.reduce(reducer),
              werte_gesamt: bar5_daten.reduce((prev, curr) => prev + curr),
            },
          });
        }

        if (bar10_daten.length > 0 && bar10_daten !== null) {
          final.push({
            name: 'bar10',
            inhalt: {
              labels: bar10_labels,
              data: bar10_daten,
              titel: bar10_titel[0],
              stimmen: bar10_stimmen,
              total: bar10_stimmen.reduce(reducer),
              werte_gesamt: bar10_daten.reduce((prev, curr) => Number(prev) + Number(curr)),
            },
          });
        }

        if (box_werte.length > 0 && box_werte !== null && box_werte !== '') {
          // console.log("BOX TITEL", box_titel)
          final.push({
            name: 'textbox',
            data: box_werte,
            titel: box_titel[0],
            ids: box_ids,
            total: box_werte.length,
          });
        }

        // console.log("FINAL",final)
        pageData.splice(i, 0, [page, final]);
      });

      setJSONdata(pageData);
      console.log("PAGE", pageData)
      // setDataForCSV(pageData);
    }
  }, [surveyData]);

  // console.log("daten", daten);
  // console.log('pageData', JSONdata);

  const getSpezifischesDatum = (von, bis) => {
    console.log('Spezifisches Datum');
    // console.log(von.concat('T00:00'));
    // console.log(bis.concat('T23:59'))

    setFilterSet(true);

    setMonth({
      start: von.concat('T00:00'),
      end: bis.concat('T23:59'),
    });
    let dtToISO = dt.toISO();
    let tempTime = DateTime.fromISO(dtToISO).setLocale('de').toFormat('dd. LLL yyyy'); //=> '2014 août 06'
    setFilterOption(`Alle Daten bis zum ${tempTime}`);

    setFilterOption(
      `Von ${DateTime.fromISO(von).setLocale('de').toFormat('dd. LLL yyyy')} bis ${DateTime.fromISO(
        bis
      )
        .setLocale('de')
        .toFormat('dd. LLL yyyy')}`
    );
  };
  const getMonat = async day => {
    setFilterSet(true);

    let aktueller_monat = dt.toISODate().slice(0, 8) + '01';
    let vorheriger_monat = dt.minus({ month: 1 }).toISODate().slice(0, 8) + '01';
    let naechster_monat = dt.plus({ month: 1 }).toISODate().slice(0, 8) + '01';

    if (day === 0) {
      setMonth({
        start: aktueller_monat,
        end: naechster_monat,
      });
      setFilterOption('Aktueller Monat');
    }

    if (day === 1) {
      setMonth({
        start: vorheriger_monat,
        end: aktueller_monat,
      });
      setFilterOption('Vorheriger Monat');
    }

    if (day === 2) {
      setMonth({
        start: '1971-04-20T11:32:00.000-04:00',
        end: dt.toISO(),
      });
      let dtToISO = dt.toISO();
      let tempTime = DateTime.fromISO(dtToISO).setLocale('de').toFormat('dd. LLL yyyy'); //=> '2014 août 06'
      setFilterOption(`Alle Daten bis zum ${tempTime}`);
    }
  };

  useEffect(() => {
    if (month) {
      console.log(':: monat start ::', month.start);
      console.log(':: monat end ::', month.end);
      let filtered_by_date = alleDaten.filter(
        e =>
          DateTime.fromISO(e.createdAt).toMillis() >= DateTime.fromISO(month.start).toMillis() &&
          DateTime.fromISO(e.createdAt).toMillis() <= DateTime.fromISO(month.end).toMillis() &&
          e.surveyName === chooseSurvey.name
      );

      console.log(':: GEFILTERT ::', filtered_by_date);
      setSurveyData(filtered_by_date);
    }
  }, [month]);

  useEffect(() => {
    // console.log('chooseSurvey', chooseSurvey);
    if (chooseSurvey) {
      let filtered_by_survey = alleDaten.filter(e => e.surveyName === chooseSurvey.name);

      console.log('FI', filtered_by_survey);
      let currentPageMax = [];
      filtered_by_survey.map(e => {
        if (e.currentPage) {
          currentPageMax.push(e.currentPage);
        }
      });
      // console.log('temp pre MAX', currentPageMax);
      setCurrentPageMax(Math.max(...currentPageMax));

      setSurveyData(filtered_by_survey);
      // setDataForCSV(filtered_by_survey);
    }
  }, [chooseSurvey]);

  const CSVtwo = () => {
    // if (data.length > 0) {
    let filtered_by_survey;
    if (deepSteps) {
      // console.log("DDDDEEEP", surveyData)
      filtered_by_survey = surveyData;
    } else {
      // console.log("FILTERED BY SURVEEEEY")
      filtered_by_survey = alleDaten.filter(e => e.surveyName === chooseSurvey.name);
    }
    console.log(chooseSurvey.name);
    console.log(filtered_by_survey);
    let titles = [];
    for (let fbs_nr = 0; fbs_nr < filtered_by_survey.length; fbs_nr++) {
      for (let resp_nr = 0; resp_nr < filtered_by_survey[fbs_nr].surveyResponse.length; resp_nr++) {
        let json = {
          title: filtered_by_survey[fbs_nr].surveyResponse[resp_nr].title,
          pageid: filtered_by_survey[fbs_nr].surveyResponse[resp_nr].pageid,
        };
        // titles.push(filtered_by_survey[fbs_nr].surveyResponse[resp_nr].title);
        titles.push(json);
      }
    }

    titles = titles.filter(
      (thing, index, self) =>
        index === self.findIndex(t => t.name === thing.name && t.pageid === thing.pageid) // json sortiert -> titel & pageid
    );
    console.log(titles.length);

    let fragen = [];

    for (let i = 1; i < titles.length; i++) {
      let loop_fragen = [];
      console.log(titles[i].pageid);
      let x = filtered_by_survey.filter(e =>
        e.surveyResponse.filter(res => {
          if (res.pageid === titles[i].pageid) {
            res.responseData.filter(x => loop_fragen.push(x.question));
          }
        })
      );
      loop_fragen = Array.from(new Set(loop_fragen));
      fragen.push(loop_fragen);
    }

    // console.log(fragen);

    let array_fragen = [];
    fragen.forEach((index, seite) =>
      index.forEach(frage =>
        array_fragen.push(`Seite ${seite + 1} - ${frage.replace(/[^a-zA-Z0-9]/g, ' ')}`)
      )
    );
    array_fragen.splice(0, 0, 'Erstellt am');
    console.log('FRAGEN array', array_fragen);

    let array_fragen_ohne_seite = [];
    fragen.forEach(index => index.forEach(frage => array_fragen_ohne_seite.push(frage)));
    array_fragen_ohne_seite.splice(0, 0, 'Erstellt am');

    console.log('FRAGEN array OHNE seite', array_fragen_ohne_seite);

    const csv = [];

    csv.push(array_fragen);

    filtered_by_survey.forEach(durchlauf => {
      let teilnehmer = [];
      let erstelldatum = durchlauf.createdAt;

      array_fragen_ohne_seite.forEach(frage => {
        let antwort = '';
        // console.log(frage);

        durchlauf.surveyResponse.forEach(seite =>
          seite.responseData.forEach(element => {
            if (element.question === frage) {
              antwort = element.response;
            }
          })
        );

        if (antwort === ('' || null)) {
          antwort = 'leer';
        }

        if (typeof antwort !== 'string') {
          antwort = antwort.toString();
        }

        antwort = antwort.replace(/\u00dc/g, 'UE');
        antwort = antwort.replace(/\u00c4/g, 'AE');
        antwort = antwort.replace(/\u00d6/g, 'OE');
        antwort = antwort.replace(/\u00fc/g, 'ue');
        antwort = antwort.replace(/\u00e4/g, 'ae');
        antwort = antwort.replace(/\u00f6/g, 'oe');
        antwort = antwort.replace(/\u00df/g, 'ss');
        antwort = antwort.replace(/[^a-zA-Z0-9]/g, ' ');
        teilnehmer.push(antwort);

        // console.log("ERSTELLT", erstelldatum)
      });
      teilnehmer.splice(0, 1, erstelldatum);

      // console.log('TEILNEHMER', teilnehmer);
      csv.push(teilnehmer);
      console.log('CSVlog', csv);
    });

    // console.log('CSV',csv)
    toCSV(csv);
    //       filtered_by_survey.foreach(durchlauf=>

    // durchlauf){
    //foreach(frage=>array_fragen_ohne_seite){

    //}

    //.surveyResponse.forEach(

    // inhalt => inhalt.filter( e => e.question ===   )

    // let teilnehmer = array()
    //
    //           durchlauf.find(frage.)

    //           telnehmer.push(antwort)
    //         }

    //         csv.push( teilnehmer)

    //       }

    // console.log('DATA FROM CSV2', data);
    // }
  };

  useEffect(() => {
    dataForCSV && CSVtwo(dataForCSV);
  }, [dataForCSV]);

  useEffect(() => {
    highlight && console.log(highlight);
  }, [highlight]);
  useEffect(() => {
    if (daten) {
      const finalBlockanswered = daten
        .map(e => e.surveyResponse[5])
        .filter(e => e)
        .map(e => e.responseData['Text eingeben'])
        .filter(e => e);

      setFilteredAnswers(finalBlockanswered);
    }
  }, [daten]);

  const customAlertOptions = {
    cssClass: 'custom-button-class',
  };

  const toCSV = data => {
    alleDaten &&
      jsonexport(data, function (err, data) {
        let blob = new Blob([data], { type: 'text/csv' });
        const objectURL = URL.createObjectURL(blob);
        saveAs(objectURL, 'csv-export.csv');
        if (err) return console.error(err);
      });
  };

  const surveys = nummer => {
    let temp = [];
    let fail = [];
    surveyData.map(e => {
      if (e.currentPage) {
        temp.push(e.currentPage);
      } else {
        fail.push(e.currentPage);
      }
    });
    let hoechstwert = Math.max(...temp);

    let absolviert = temp.filter(e => e >= currentPageMax);
    let vorzeitig_abgebrochen = temp.filter(e => e < currentPageMax && e > 0);

    if (nummer === 1) {
      return absolviert.length;
    }
    if (nummer === 2) {
      return vorzeitig_abgebrochen.length;
    }
    if (nummer === 3) {
      return absolviert;
    }

    if (nummer === 4) {
      return fail.length;
    }
  };

  const classes = useStyles();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Statistische Auswertung</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonToolbar className="ion-padding-start">
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href="/">
              Administration
            </Link>
            <Typography color="textPrimary">Statistische Auswertung</Typography>
          </Breadcrumbs>
        </IonToolbar>

        <IonGrid>
          <IonRow>
            <IonItem style={{ width: '100%' }}>
              <IonLabel>Bitte Umfrage auswählen</IonLabel>
              <IonSelect
                // value={chooseSurvey}
                className="selectCss"
                interfaceOptions={customAlertOptions}
                // style={{ maxWidth: "100% !important;" }}
                okText="Auswählen"
                cancelText="Abbrechen"
                onIonChange={e => {
                  console.log('onIonChange', e.detail.value);
                  setHighlight(false);
                  setFilterSet(false);
                  setFilterOption(false);
                  setDeepSteps([]);
                  setDeep([]);
                  setChooseSurvey(e.detail.value);
                }}
                // onIonChange={(e) => setChooseSurvey(e)}
              >
                {surveyList &&
                  surveyList.map((e, i) => (
                    <IonSelectOption key={i} value={e}>
                      {e.name}
                    </IonSelectOption>
                  ))}
              </IonSelect>
            </IonItem>
          </IonRow>
          <IonRow
            style={{ background: 'white' }}
            // id={"divToPrint"}
            ref={ref}
            className="ion-padding-start ion-padding-end">
            <IonCol size="12">
              {chooseSurvey && (
                <div
                  style={{
                    padding: '10px',
                    display: 'flex',
                    // alignItems: 'flex-end',
                    flexWrap: 'wrap',
                  }}>
                  <span
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      marginBottom: '25px',
                    }}>
                    <TextField
                      id="date_von"
                      label="Von"
                      type="date"
                      // defaultValue="2017-05-24"
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />

                    <TextField
                      id="date_bis"
                      label="Bis"
                      type="date"
                      // defaultValue="2017-05-25"
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      style={{ marginRight: '10px' }}
                    />
                  </span>

                  <IonButton
                    size="small"
                    color={highlight === 'specific' ? 'primary' : 'light'}
                    shape="round"
                    style={{ marginRight: '100px' }}
                    onClick={() => {
                      setHighlight('specific');
                      getSpezifischesDatum(
                        document.getElementById('date_von').value,
                        document.getElementById('date_bis').value
                      );
                    }}>
                    anzeigen
                  </IonButton>

                  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <span>
                      {/* {month && (
                        <>
                          <IonButton
                            shape="round"
                            size="small"
                            color="secondary"
                            style={{ marginRight: "10px" }}
                            onClick={() => getMonat(2)}
                          >
                            Filter zurücksetzen
                          </IonButton>
                        </>
                      )} */}

                      <IonButton
                        size="small"
                        color={highlight === 'prev' ? 'primary' : 'light'}
                        shape="round"
                        style={{ marginRight: '10px' }}
                        onClick={() => {
                          setHighlight('prev');
                          getMonat(1);
                        }}>
                        Vorheriger Monat
                      </IonButton>
                      <IonButton
                        color={highlight === 'current' ? 'primary' : 'light'}
                        size="small"
                        shape="round"
                        style={{ marginRight: '10px' }}
                        onClick={() => {
                          setHighlight('current');
                          getMonat(0);
                        }}>
                        Aktueller Monat
                      </IonButton>
                      <IonButton
                        color={highlight === 'alldata' ? 'primary' : 'light'}
                        style={{ marginRight: '10px' }}
                        shape="round"
                        size="small"
                        onClick={() => {
                          setHighlight('alldata');
                          getMonat(2);
                        }}>
                        Alle Daten
                      </IonButton>
                      <IonButton
                        color="secondary"
                        disabled={printing}
                        shape="round"
                        size="small"
                        onClick={() => pdfToHTML()}>
                        {printing ? 'Moment...' : 'Ausdrucken'}
                      </IonButton>
                    </span>
                    <span>
                      <IonButton
                        shape="round"
                        size="small"
                        color="warning"
                        onClick={() => CSVtwo()}>
                        CSV Export
                      </IonButton>

                      {chooseSurvey && credentials.privilages === 2 && (
                        <>
                          <IonButton
                            shape="round"
                            size="small"
                            color="danger"
                            onClick={() => handleClickOpen1()}>
                            Umfrage löschen
                          </IonButton>
                        </>
                      )}
                    </span>
                  </div>
                </div>
              )}
              {JSONdata && (
                <>
                  <IonCol size="12">
                    <div style={{ height: '60px', padding: '10px' }}>
                      <IonButton
                        color={highlightComplete === 'canceled' ? 'primary' : 'light'}
                        shape="round"
                        size="small"
                        onClick={() => {
                          setHighlightComplete('canceled');
                          setFilterCompleteSet(true);
                          setFilterCompleteOption('Zeige nur ABGEBROCHENE Umfragen');
                          setDeep(deepSteps);
                        }}>
                        Zeige nur abgebrochene Umfragen
                      </IonButton>
                      <IonButton
                        color={highlightComplete === 'complete' ? 'primary' : 'light'}
                        shape="round"
                        size="small"
                        onClick={() => {
                          setHighlightComplete('complete');
                          setFilterCompleteSet(true);
                          setFilterCompleteOption('Zeige nur VOLLSTÄNDIGE Umfragen');
                          setDeep(deepSteps);
                        }}>
                        Zeige nur vollständige Umfragen
                      </IonButton>
                    </div>
                  </IonCol>
                </>
              )}
              {chooseSurvey && (
                <>
                  <Dialog
                    open={warningA}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">
                      {'Wollen Sie die Umfrage-Ergebnisse wirklich löschen?'}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        <div style={{ textAlign: 'center' }}>
                          Wenn Sie <b>OK</b> klicken, gehen alle Daten der Umfrage
                          <p>
                            <IonChip>{chooseSurvey.name}</IonChip>
                          </p>{' '}
                          verloren.
                          <p />
                          <b>Möchten Sie fortfahren?</b>
                        </div>
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <IonButton onClick={handleClose} color="primary">
                        Abbrechen
                      </IonButton>
                      <IonButton onClick={handleClickOpen2} color="primary" autoFocus>
                        OK
                      </IonButton>
                    </DialogActions>
                  </Dialog>

                  <Dialog
                    open={warningB}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">
                      {'Wollen Sie die Umfrage-Ergebnisse wirklich löschen?'}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        <div style={{ textAlign: 'center' }}>
                          <b>
                            {pwdError ? (
                              <>
                                <IonIcon
                                  icon={warning}
                                  style={{ fontSize: '35px' }}
                                  color="danger"
                                />
                                <p />{' '}
                              </>
                            ) : (
                              ''
                            )}
                            <TextField
                              name="loginP"
                              id="loginP"
                              label={pwdError ? 'Falsches Passwort' : 'Passwort'}
                              onChange={() => onChangePass()}
                              type="password"
                              variant="outlined"
                              error={pwdError}
                            />
                            <p />
                            Geben Sie bitte Ihr Passwort ein.
                          </b>
                        </div>
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <IonButton onClick={handleClose} color="primary">
                        Abbrechen
                      </IonButton>
                      {pwd && (
                        <IonButton
                          onClick={() => setTriggerDeleteResponses(true)}
                          color="danger"
                          autoFocus>
                          Löschen
                        </IonButton>
                      )}
                    </DialogActions>
                  </Dialog>
                </>
              )}
            </IonCol>
            {filterSet && (
              <>
                <IonCol size="6">
                  <IonCard>
                    <div style={{ height: '60px', padding: '10px' }}>
                      <b>Datums-Filter:</b>
                      <span>
                        {filterOption}
                        <IonIcon
                          onClick={() => {
                            setHighlight(false);
                            setHighlightComplete(false);
                            getMonat(2);
                            setFilterSet(false);
                            setFilterOption(false);
                          }}
                          style={{ fontSize: '20px' }}
                          icon={backspace}
                          color="danger"
                        />
                      </span>
                    </div>
                  </IonCard>
                </IonCol>
              </>
            )}
            {filterCompleteSet && (
              <>
                <IonCol size="6">
                  <IonCard>
                    <div style={{ height: '60px', padding: '10px' }}>
                      <b>Vollständigkeit:</b>
                      <span>
                        {filterCompleteOption}
                        <IonIcon
                          onClick={() => {
                            setHighlightComplete(false);
                            setFilterCompleteOption(false);
                            setFilterCompleteSet(false);
                          }}
                          style={{ fontSize: '20px' }}
                          icon={backspace}
                          color="danger"
                        />
                      </span>
                    </div>
                  </IonCard>
                </IonCol>
              </>
            )}

            {deepSteps && deepSteps.length > 0 && (
              <>
                <IonCol size="12">
                  <IonCard>
                    <div style={{ padding: '10px' }}>
                      <b>Detail-Filter:</b>
                    </div>
                    <div>
                      {deepSteps.map((e, index) => (
                        <>
                          <div style={{ padding: '10px' }}>
                            [ {index + 1} ] — {e.question} — <b>{e.option}</b>
                            <IonIcon
                              onClick={() => removeDeepSteps(index)}
                              style={{ fontSize: '20px' }}
                              icon={closeCircle}
                              color="danger"
                            />
                          </div>
                        </>
                      ))}
                    </div>
                  </IonCard>
                </IonCol>
              </>
            )}

            {JSONdata &&
              chooseSurvey &&
              JSONdata.map(
                (el, i) => (
                  // {console.log("ELEMENT", el)

                  <IonRow style={{ width: '100%' }} ref={addToRefs}>
                    {el[1].length > 0 ? (
                      <IonCol key={i} size="12" className="topics ion-padding-start">
                        <div className="vcenter">{el[0]} </div>
                      </IonCol>
                    ) : (
                      ''
                    )}

                    {el[1].map((element, index) => (
                      <>
                        {element.name === 'pie'
                          ? element.inhalt.labels.map((label, index) => {
                              let sizedefault = 'medium';
                              if (element.inhalt.labels[index].length > 25) {
                                sizedefault = 'smaller';
                              }

                              if (element.inhalt.labels[index].length > 50) {
                                sizedefault = 'x-small';
                              }

                              if (
                                // Object.keys(element.inhalt.data[index]).length <=
                                //   4 &&
                                element.inhalt.stimmen[index] > 0
                              ) {
                                return (
                                  <IonCol key={index} size="4" className="ion-padding-start">
                                    <div className="toptext">
                                      <IonChip style={{ fontSize: sizedefault }}>
                                        {element.inhalt.labels[index]}
                                      </IonChip>
                                    </div>
                                    <div>
                                      {pieFilterOptions(
                                        element.inhalt.labels[index],
                                        element.inhalt.data[index]
                                      )}
                                    </div>
                                    <Pie
                                      height={175}
                                      plugins={[ChartDataLabels]}
                                      data={{
                                        labels: Object.keys(element.inhalt.prozente[index]),
                                        // labels: Object.keys(prozent),

                                        datasets: [
                                          {
                                            label: '# of Votes',
                                            data: Object.values(element.inhalt.prozente[index]),
                                            absolut: Object.values(element.inhalt.data[index]),
                                            backgroundColor: [
                                              '#cdffa5',
                                              '#e8fc8f',
                                              '#a3ffda',
                                              '#fcd1c2',
                                              '#f98f7a',
                                              '#89f4d2',
                                              '#f9e5a9',
                                              '#8b90e5',
                                              '#ffdaa3',
                                              '#f7b0a5',
                                              '#ffd2b7',
                                              '#fffdb7',
                                              '#bde3f9',
                                              '#9d96ff',
                                              '#f7b16f',
                                              '#d3aaf7',
                                              '#bdcaf9',
                                              '#ffc1d6',
                                              '#c2cbfc',
                                              '#bccdff',
                                              '#f7aae6',
                                              '#e3ff77',
                                              '#aaffb3',
                                              '#e4ffaf',
                                              '#bbc7f7',
                                              '#99fcd3',
                                              '#6bcaea',
                                              '#f29e93',
                                              '#ffcfcc',
                                              '#d9aefc',
                                              '#a5ffdb',
                                              '#8477e5',
                                              '#f2c9ff',
                                              '#8369e0',
                                              '#a8acff',
                                              '#a1fcc4',
                                              '#80ddf2',
                                              '#ff82c0',
                                              '#f9e9a2',
                                              '#91f7c1',
                                              '#efe375',
                                              '#b39eed',
                                              '#b0fc80',
                                              '#f4c097',
                                              '#f977ef',
                                              '#c9dcff',
                                              '#b1abf2',
                                              '#8ac9fc',
                                              '#ffbae8',
                                              '#c2a1ed',
                                              '#d4f48d',
                                              '#b2f780',
                                              '#c7ffaf',
                                              '#f9e9bb',
                                              '#c4a1fc',
                                              '#aeff96',
                                              '#b383e2',
                                              '#f26fde',
                                              '#b0fc7e',
                                              '#ffe4bc',
                                              '#fcd49c',
                                              '#ae9ffc',
                                              '#94f785',
                                              '#d5fc7b',
                                              '#a2f9cf',
                                              '#bdd8f9',
                                              '#a7dcef',
                                              '#f9f57f',
                                              '#9ff9ae',
                                              '#c2fc9f',
                                              '#87cced',
                                              '#afff9b',
                                              '#75daf9',
                                              '#f986cb',
                                              '#a5f984',
                                              '#f99c8e',
                                              '#ffbffd',
                                              '#f27dc3',
                                              '#fcbdcf',
                                              '#719ffc',
                                              '#72f9d1',
                                              '#f29be5',
                                              '#d9ffaa',
                                              '#6fb8ed',
                                              '#e6ffb2',
                                              '#70efb2',
                                              '#e6bffc',
                                              '#bdffad',
                                              '#8ff7f0',
                                              '#79c1f2',
                                              '#ffc7af',
                                              '#f9adff',
                                              '#9d90f4',
                                              '#9df9a0',
                                              '#f4a8d2',
                                              '#82bfe5',
                                              '#d3ffaf',
                                              '#8ec5ed',
                                              '#f4796e',
                                              '#7bfcb9',
                                              '#d9f293',
                                              '#a8ffa9',
                                              '#7ee7ea',
                                              '#fcffaf',
                                              '#adb8ff',
                                              '#cbbdf9',
                                              '#99f78f',
                                              '#97f274',
                                              '#b9f99a',
                                              '#80f2ad',
                                              '#fff3cc',
                                              '#e9b4f7',
                                              '#afffcc',
                                              '#aeed82',
                                              '#80e5e5',
                                              '#9accf9',
                                              '#f477e4',
                                              '#ffe791',
                                              '#a4fc97',
                                              '#7df299',
                                              '#fcd7b0',
                                              '#f9b8e8',
                                              '#89f9bc',
                                              '#f6b6f9',
                                              '#85fc83',
                                              '#c7e8fc',
                                              '#e98bf9',
                                              '#99ed80',
                                              '#ef92bf',
                                              '#88ef99',
                                              '#ffd9c9',
                                              '#ffcccf',
                                              '#84f4a4',
                                              '#f7b7e9',
                                              '#9dfc94',
                                              '#f4c97f',
                                              '#b38fea',
                                              '#f7bb8c',
                                              '#f1f9a4',
                                              '#ef70e7',
                                              '#a4fcf0',
                                              '#d8fc9f',
                                              '#f4b07c',
                                              '#e4f49a',
                                              '#f7c6af',
                                              '#81ff7c',
                                              '#89e5e5',
                                              '#868def',
                                              '#bbf1f9',
                                              '#a2e5ef',
                                              '#a4f9a9',
                                              '#f29785',
                                              '#b278e2',
                                              '#fcb3c4',
                                              '#e2f492',
                                              '#d5bbf9',
                                              '#9cfcac',
                                              '#b4ffad',
                                              '#94e86a',
                                              '#c372e5',
                                              '#94f79f',
                                              '#d9ff9e',
                                              '#f28295',
                                              '#8176e8',
                                              '#ff75ea',
                                              '#9bf7d1',
                                              '#c7fca1',
                                              '#85ff77',
                                              '#93ef7c',
                                              '#f7f49e',
                                              '#f99c77',
                                              '#9a8def',
                                              '#a5f279',
                                              '#987fe2',
                                              '#ff99ed',
                                              '#f2ec7b',
                                              '#f489db',
                                              '#d09cf4',
                                              '#aaef6e',
                                              '#9ea1ff',
                                              '#f4a8c8',
                                              '#87f2c7',
                                              '#f7c7a3',
                                              '#7bf7c3',
                                              '#ab66d6',
                                              '#8dfca3',
                                              '#cf7cea',
                                              '#d6bbf7',
                                              '#f9d8a9',
                                              '#f788b6',
                                              '#76f2ee',
                                              '#f78390',
                                              '#856ff7',
                                              '#fc767f',
                                              '#b9c2f7',
                                              '#dff995',
                                              '#6ff2a5',
                                              '#a3e8ed',
                                              '#e1ff9b',
                                              '#f9c6ff',
                                            ],
                                          },
                                        ],
                                      }}
                                      options={{
                                        // onClick(){ alert( Object.keys(element.inhalt.data[index])[index])},
                                        legend: { display: false },

                                        plugins: {
                                          datalabels: {
                                            color: 'black',
                                            textAlign: 'center',
                                            formatter: function (value) {
                                              return Math.round(value) + '%';
                                            },
                                            font: {
                                              weight: 'bold',
                                              size: 15,
                                            },
                                          },
                                        },

                                        tooltips: {
                                          callbacks: {
                                            label: (tooltipItems, data) => {
                                              let endung;
                                              if (
                                                data.datasets[tooltipItems.datasetIndex].absolut[
                                                  tooltipItems.index
                                                ] === 1
                                              ) {
                                                endung = ' Stimme';
                                              } else {
                                                endung = ' Stimmen';
                                              }
                                              return (
                                                data.datasets[tooltipItems.datasetIndex].absolut[
                                                  tooltipItems.index
                                                ] + endung
                                              );
                                            },
                                          },
                                        },
                                      }}
                                    />
                                    <div className="subtext">
                                      Teilnehmer gesamt : {element.inhalt.stimmen[index]}
                                    </div>
                                  </IonCol>
                                );
                              }
                            })
                          : ''}

                        {element.name === 'bar5'
                          ? doBar(
                              element.inhalt.labels,
                              element.inhalt.data,
                              5,
                              element.inhalt.total,
                              element.inhalt.werte_gesamt,
                              element.inhalt.titel,
                              element.inhalt.stimmen
                            )
                          : ''}
                        {element.name === 'bar10'
                          ? doBar(
                              element.inhalt.labels,
                              element.inhalt.data,
                              10,
                              element.inhalt.total,
                              element.inhalt.werte_gesamt,
                              element.inhalt.titel,
                              element.inhalt.stimmen
                            )
                          : ''}
                        {element.name === 'pbar'
                          ? doPieBar(
                              element.inhalt.labels,
                              element.inhalt.data,
                              element.inhalt.titel,
                              element.inhalt.stimmen,
                              element.inhalt.ids
                            )
                          : ''}

                        {element.name === 'textbox' ? (
                          <>
                            <IonCol key={i} size="12">
                              <div className="toptext">
                                <IonChip>{element.titel}</IonChip>
                              </div>
                            </IonCol>
                            <IonCol>
                              <TableContainer component={Paper}>
                                <Table
                                  className={classes.table}
                                  size="small"
                                  aria-label="a dense table">
                                  <TableBody>
                                    {element.data
                                      .filter((e, i) => e !== '' && e !== null)
                                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                      .map((e, i) => {
                                        // alert(e)

                                        // if ((e !== '') && (e !== null)) {

                                        // console.log("element",element)
                                        let jsonQuery = {
                                          question: element.titel,
                                          option: e,
                                          type: 'textbox',
                                          res_id: element.ids[i],
                                        };

                                        // console.log("ELEMENT", element)

                                        // let jsonQuery = { res_id: element.elementid };
                                        return (
                                          <TableRow key={i}>
                                            <TableCell component="th" scope="row">
                                              <div
                                                onClick={() => {
                                                  setDeep([jsonQuery]);
                                                  setDeepSteps([...deepSteps, jsonQuery]);
                                                }}>
                                                {e}
                                              </div>
                                            </TableCell>
                                          </TableRow>
                                        );
                                        // }
                                      })}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                              <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={element.data.filter(e => e !== '' && e !== null).length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                              />
                            </IonCol>
                          </>
                        ) : (
                          ''
                        )}
                      </>
                    ))}
                  </IonRow>
                )
                // }
              )}

            {!chooseSurvey && (
              <>
                <IonCol>
                  <IonCard>
                    <div className="textblock bigtext">
                      <IonIcon icon={warning} color="warning" /> Bitte wählen Sie eine Umfrage aus{' '}
                      <IonIcon icon={warning} color="warning" />
                    </div>
                  </IonCard>
                </IonCol>
              </>
            )}
          </IonRow>

          {JSONdata && chooseSurvey && (
            <IonRow>
              <IonCol size="12">
                {JSONdata && (
                  <div className="subtext">
                    Umfragen gesamt : {surveyData.length} | Vorzeitig abgebrochen : {surveys(2)} |
                    Nicht gestartet: {surveys(4)} | Komplett durchgeführt :{surveys(1)}
                  </div>
                )}
              </IonCol>
            </IonRow>
          )}
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default StatisticsOverview;
