import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React from "react";
// import { Calendar, momentLocalizer } from "react-big-calendar";
// import moment from "moment";
import AdminPanelComponent from "../components/AdminPanelComponent";

// moment.locale("en-GB");
// const localizer = momentLocalizer(moment);

const AdminPanel = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Admin Panel</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Admin Panel</IonTitle>
          </IonToolbar>
        </IonHeader>

        <AdminPanelComponent />
      </IonContent>
    </IonPage>
  );
};

export default AdminPanel;
