import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import React, { useState } from "react";
import "./Login.css";
import Cookies from "universal-cookie";
import SaintLogo from "../img/saint.png";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "50ch",
    },
  },
}));

const Login = ({ setAuthenticate }) => {
  const [usr, setUsr] = useState("");
  const [pwd, setPwd] = useState("");

  const [pwdError, setPasswordError] = useState(false);

  const fetch_Login = async () => {
    try {
      const initfetch = await fetch(
        process.env.NODE_ENV === "production"
          ? `https://fb-backend.procuratio.com/admin/login/`
          : `http://localhost:3000/`,
          
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: usr,
            password: pwd,
          }),
        }
      );

      const data = await initfetch.json();

      if (data.token) {
        const cookies = new Cookies();
        console.log(":: ok ::");
        setPwd("");
        setUsr("");
        cookies.set("admincookie", data.token, {
          path: "/",
          expires: new Date(Date.now() + 222225920),
        });
        setAuthenticate(true);
      } else {
        console.log(data.error);
        setPasswordError(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeUser = (e) => {
    setUsr(document.getElementById("loginU").value);
  };

  const onChangePass = (e) => {
    setPwd(document.getElementById("loginP").value);
    setPasswordError(false);
  };
  const classes = useStyles();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Admin Login</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Menu Creator</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className="wrapper">
          <div>
            <form
              id="box"
              className={classes.root}
              noValidate
              autoComplete="off"
            >
              <div className="img">
                <img src={SaintLogo} alt="" />
              </div>

              <div className="content">
                <div className="spacer">
                  <TextField
                    id="loginU"
                    label="Benutzername"
                    value={usr}
                    onChange={() => onChangeUser()}
                    variant="outlined"
                  />
                </div>
                <div className="spacer">
                  <TextField
                    name="loginP"
                    id="loginP"
                    label="Passwort"
                    onChange={() => onChangePass()}
                    type="password"
                    variant="outlined"
                  />
                </div>
              </div>
              <Button
                variant="contained"
                size="large"
                color="primary"
                // onClick={() => onVerify(usr, pwd)}
                onClick={() => fetch_Login(true)}
              >
                Login
              </Button>
              {pwdError && (
                <>
                  <div
                    className="content"
                    style={{ textAlign: "center", color: "red" }}
                  >
                    Eingabe inkorrekt.Bitte überprüfen Sie die eingegebenen
                    Daten
                  </div>
                </>
              )}

<div className="info">
                Bei Fragen oder Problemen wenden Sie sich bitte an
                <div className="infodaten">info@solufind.de</div>
                oder
                <div className="infodaten">02104 - 2863695</div>
              </div>
            </form>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Login;
