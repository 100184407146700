import {
  IonToolbar,
  IonRow,
  IonButton,
  IonLabel,
  IonCheckbox,
  IonList,
  IonItem,
  IonReorderGroup,
  IonReorder,
  IonCol,
  IonInput,
  IonIcon,
  IonSelect,
  IonSelectOption,
  IonChip,
} from "@ionic/react";
import React, { useState, useEffect } from "react";
import { arrowForwardOutline } from "ionicons/icons";

import NewInput from "./NewInput";

import "../app.css";

const SurveyPageRenderer = ({
  elements,
  pagetitle,
  pageid,
  currentSurveyId,
  setTriggerGet,
  setEditQuestionMode,
  setElementAndPageId,
  currentSurvey,
  credentials,
  pageconditions,
}) => {
  const [newQuestion, setNewQuestion] = useState();
  const [renameQuestion, setRenameQuestion] = useState(false);
  const [elementChecked, setElementChecked] = useState();
  const [reorder, setReorder] = useState(true);
  const [newElementsOrder, setNewElementsOrder] = useState();
  const [editPageMode, setEditPageMode] = useState(null);
  const [pageRenameMode, setPageRenameMode] = useState(null);
  const [pageName, setPageName] = useState(pagetitle);
  const [pageRequired, setPageRequired] = useState(
    pageconditions.isRequired === false ? false : true
  );
  const [questionOnPage, setQuestionOnPage] = useState(
    pageconditions.isRequired === false
      ? {
          pageTitle: pageconditions.optionalanswercondition.pagetitle,
          pageId: pageconditions.optionalanswercondition.pageid,
        }
      : null
  );
  const [elementOnPage, setElementOnPage] = useState(
    pageconditions.isRequired === false
      ? {
          questionTitle: pageconditions.optionalanswercondition.elementtitle,
          questionId: pageconditions.optionalanswercondition.elementid,
        }
      : null
  );
  const [answerOnPage, setAnswerOnPage] = useState(
    pageconditions.isRequired === false
      ? pageconditions.optionalanswercondition.response
      : null
  );

  useEffect(() => elements && setNewElementsOrder(elements), [elements]);

  const doReorder = (event) => {
    const savedElements = [...newElementsOrder];
    let draggedItem = savedElements.splice(event.detail.from, 1)[0];
    savedElements.splice(event.detail.to, 0, draggedItem);
    setNewElementsOrder(savedElements);
    event.detail.complete();
  };

  const customAlertOptions = {
    cssClass: "custom-button-class",
  };

  const questionHandler = async (trigger) => {
    try {
      const initfetch = await fetch(
        process.env.NODE_ENV === "production"
          ? `https://fb-backend.procuratio.com/editsurvey/`
          : `http://localhost:3000/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            clientid: credentials,
            trigger,
            surveyid: currentSurveyId,
            pageid,
            ...(elementChecked && { elementid: elementChecked._id }),
            ...(newElementsOrder && { newpageorder: newElementsOrder }),
            ...(pageName && { newpagename: pageName }),
            ...(pageRequired !== null && { pagerequirement: pageRequired }),
            ...(questionOnPage && {
              condquestionid: questionOnPage.pageId,
              condpagetitle: questionOnPage.pageTitle,
            }),
            ...(elementOnPage && {
              condelementid: elementOnPage.questionId,
              condquestiontitle: elementOnPage.questionTitle,
            }),
            ...(answerOnPage && { condrequiredanswer: answerOnPage }),
          }),
        }
      );

      const data = await initfetch.json();

      console.log(data);
    } catch (err) {
      console.log(err);
    } finally {
      setElementChecked(null);
      setEditPageMode(false);
      setTriggerGet(true);
    }
  };

  const compareWith = (o1, o2) => {
    return o1 && o2 ? o1.pageTitle === o2.pageTitle : o1 === o2;
  };

  const compareWith1 = (o1, o2) => {
    return o1 && o2 ? o1.questionTitle === o2.questionTitle : o1 === o2;
  };

  return (
    <>
      <IonRow>
        <IonToolbar>
          <IonButton
            onClick={() => {
              setEditPageMode((e) => {
                if (e) {
                  setEditPageMode(false);
                  if (pageconditions.isRequired) {
                    setQuestionOnPage(null);
                    setElementOnPage(null);
                    setAnswerOnPage(null);
                    setPageRequired(true);
                  }
                } else {
                  setEditPageMode(true);
                }
              });
            }}
            size="small"
            expand="full"
            fill="outline"
          >
            {pagetitle}
          </IonButton>

          {editPageMode && (
            <IonRow style={{ borderBottom: "2px solid #2174dc" }}>
              <IonCol size="12">
                <IonItem style={{ width: "100%" }}>
                  <IonButton
                    shape="round"
                    fill="outline"
                    slot="end"
                    onClick={() => questionHandler("deletepage")}
                  >
                    Delete Page
                  </IonButton>
                </IonItem>
              </IonCol>
              {pageRenameMode ? (
                <IonCol size="12">
                  <IonItem style={{ width: "100%" }}>
                    <IonInput
                      value={pageName}
                      placeholder={pageName}
                      onIonChange={(e) => setPageName(e.detail.value)}
                    ></IonInput>

                    <IonButton
                      style={{ marginLeft: "4px" }}
                      slot="end"
                      // onClick={() => optionHandler("renamesurvey", e1._id, newName)}
                      onClick={() => setPageRenameMode(false)}
                    >
                      Speichern
                    </IonButton>
                    <IonButton
                      style={{ marginLeft: "4px" }}
                      slot="end"
                      // onClick={() => optionHandler("renamesurvey", e1._id, newName)}
                      onClick={() => setPageRenameMode(false)}
                    >
                      Abbrechen
                    </IonButton>
                  </IonItem>
                </IonCol>
              ) : (
                <IonCol size="12">
                  <IonItem style={{ width: "100%" }}>
                    <IonLabel>{pageName}</IonLabel>
                    <IonButton
                      style={{ marginLeft: "4px" }}
                      slot="end"
                      // onClick={() => optionHandler("renamesurvey", e1._id, newName)}
                      onClick={() => setPageRenameMode(true)}
                    >
                      Umbenennen
                    </IonButton>
                  </IonItem>
                </IonCol>
              )}
              <IonCol size="12">
                <IonItem style={{ width: "100%" }}>
                  <IonLabel>Ist dies eine Pflichtseite?</IonLabel>
                  <IonSelect
                    className="selectCss"
                    interfaceOptions={customAlertOptions}
                    value={pageRequired}
                    placeholder="auswählen"
                    onIonChange={(e) => {
                      setPageRequired(e.detail.value);
                      if (e.detail.value) {
                        setQuestionOnPage(null);
                        setElementOnPage(null);
                        setAnswerOnPage(null);
                      }
                    }}
                  >
                    <>
                      <IonSelectOption value={true}>{"ja"}</IonSelectOption>{" "}
                      <IonSelectOption value={false}>{"nein"}</IonSelectOption>{" "}
                    </>
                  </IonSelect>
                </IonItem>
              </IonCol>
              {pageRequired !== true && (
                <IonCol size="12">
                  <IonItem style={{ width: "100%" }}>
                    <IonLabel>
                      Auf welcher Seite befindet sich die Frage?
                    </IonLabel>
                    <IonSelect
                      className="selectCss"
                      interfaceOptions={customAlertOptions}
                      compareWith={compareWith}
                      value={questionOnPage}
                      placeholder="Auswählen"
                      onIonChange={(e) => {
                        setQuestionOnPage(e.detail.value);
                        if (e.detail.value) {
                          setElementOnPage(null);
                          setAnswerOnPage(null);
                        }
                      }}
                    >
                      {currentSurvey.pages.map((e, ind) => (
                        <IonSelectOption
                          key={ind}
                          value={{
                            pageTitle: e.title,
                            pageId: e._id,
                          }}
                        >
                          {e.title}
                        </IonSelectOption>
                      ))}
                    </IonSelect>
                  </IonItem>
                </IonCol>
              )}
              {questionOnPage !== null && pageRequired !== true && (
                <IonCol size="12">
                  <IonItem style={{ width: "100%" }}>
                    {currentSurvey.pages
                      .filter(
                        (element) => element._id === questionOnPage.pageId
                      )[0]
                      .elements.filter(
                        (e) => e.type === "boolean" || e.type === "dropdown"
                      ).length ? (
                      <>
                        <IonLabel>Bitte Frage auswählen</IonLabel>
                        <IonSelect
                          className="selectCss"
                          interfaceOptions={customAlertOptions}
                          compareWith={compareWith1}
                          value={elementOnPage}
                          placeholder="Auswählen"
                          onIonChange={(e) => {
                            setElementOnPage(e.detail.value);
                            if (e.detail.value) {
                              setAnswerOnPage(null);
                            }
                          }}
                        >
                          {currentSurvey.pages
                            .filter(
                              (element) => element._id === questionOnPage.pageId
                            )[0]
                            .elements.filter(
                              (e) =>
                                e.type === "boolean" || e.type === "dropdown"
                            )
                            .map((e, ind) => (
                              <IonSelectOption
                                key={ind}
                                value={{
                                  questionTitle: e.title,
                                  questionId: e._id,
                                }}
                              >
                                {e.title}
                              </IonSelectOption>
                            ))}
                        </IonSelect>
                      </>
                    ) : (
                      <IonLabel>
                        Sorry. Das ist keine berechtigte Frage
                      </IonLabel>
                    )}
                  </IonItem>
                </IonCol>
              )}
              {elementOnPage !== null &&
                questionOnPage !== null &&
                pageRequired !== true && (
                  <IonCol size="12">
                    <IonItem style={{ width: "100%" }}>
                      <IonLabel>Bitte Antort auswählen:</IonLabel>
                      <IonSelect
                        className="selectCss"
                        interfaceOptions={customAlertOptions}
                        multiple
                        value={answerOnPage}
                        placeholder="Auswählen"
                        onIonChange={(e) => setAnswerOnPage(e.detail.value)}
                      >
                        {currentSurvey.pages
                          .filter(
                            (element) => element._id === questionOnPage.pageId
                          )[0]
                          .elements.filter(
                            (elem) => elem._id === elementOnPage.questionId
                          )[0].type === "boolean" ? (
                          <>
                            <IonSelectOption>{"ja"}</IonSelectOption>{" "}
                            <IonSelectOption>{"nein"}</IonSelectOption>{" "}
                          </>
                        ) : currentSurvey.pages
                            .filter(
                              (element) => element._id === questionOnPage.pageId
                            )[0]
                            .elements.filter(
                              (elem) => elem._id === elementOnPage.questionId
                            )[0].type === "dropdown" ? (
                          <>
                            {currentSurvey.pages
                              .filter(
                                (element) =>
                                  element._id === questionOnPage.pageId
                              )[0]
                              .elements.filter(
                                (elem) => elem._id === elementOnPage.questionId
                              )[0]
                              .choices.map((e, ind) => (
                                <IonSelectOption key={ind} value={e}>
                                  {e}
                                </IonSelectOption>
                              ))}
                          </>
                        ) : null}
                      </IonSelect>
                    </IonItem>
                  </IonCol>
                )}
              <IonCol size="12">
                <IonItem style={{ width: "100%" }}>
                  <IonButton
                    style={{ marginLeft: "4px" }}
                    slot="end"
                    shape="round"
                    fill="outline"
                    // onClick={() => optionHandler("renamesurvey", e1._id, newName)}
                    onClick={() => questionHandler("editpage")}
                  >
                    Speichern
                  </IonButton>
                  <IonButton
                    style={{ marginLeft: "4px" }}
                    slot="end"
                    shape="round"
                    fill="outline"
                    // onClick={() => optionHandler("renamesurvey", e1._id, newName)}
                    onClick={() => {
                      setEditPageMode(false);
                      if (pageconditions.isRequired) {
                        setQuestionOnPage(null);
                        setElementOnPage(null);
                        setAnswerOnPage(null);
                        setPageRequired(true);
                      }
                    }}
                  >
                    Abbrechen
                  </IonButton>
                </IonItem>
              </IonCol>
            </IonRow>
          )}
        </IonToolbar>
      </IonRow>

      {pageconditions.isRequired === false && (
        <IonRow
          style={{
            paddingBottom: "10px",
            marginBottom: "20px",
            borderBottom: "1px solid #2174dc",
          }}
        >
          <div style={{ display: "flex" }}>
            <div
              style={{
                width: "120px",
                background: "#f98e8e6e",
                padding: "15px",
              }}
            >
              {"Condition:"}
            </div>
            <div
              style={{
                padding: "15px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div>
                {"Page: " + pageconditions.optionalanswercondition.pagetitle}
              </div>
              <div
                style={{
                  display: "flex",
                  paddingTop: "5px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <IonIcon icon={arrowForwardOutline} />
                  {"  "}
                  <div>Question:</div>
                  {"  "}
                  <div>
                    {" "}
                    {pageconditions.optionalanswercondition.elementtitle}
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    paddingLeft: "20px",
                  }}
                >
                  <IonIcon icon={arrowForwardOutline} />
                  {"  "}
                  <div>Response:</div>
                  {"  "}
                  {pageconditions.optionalanswercondition.response.map(
                    (e, ind, arr) => (
                      <div key={ind}>
                        {arr.length - 1 === ind ? e : e + ","}
                        {"  "}
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </IonRow>
      )}

      <IonRow>
        <IonCol size="12">
          <IonItem className="ion-align-items-end" lines="none">
            {!elementChecked || !reorder ? (
              <IonButton
                disabled={!reorder || newQuestion}
                size="medium"
                shape="round"
                onClick={() => setNewQuestion(true)}
              >
                Element hinzufügen
              </IonButton>
            ) : (
              <>
                <IonButton
                  size="medium"
                  shape="round"
                  disabled={
                    !elementChecked || !reorder || renameQuestion ? true : false
                  }
                  onClick={() => setEditQuestionMode(true)}
                >
                  Ändern
                </IonButton>
                <IonButton
                  size="medium"
                  shape="round"
                  disabled={
                    !elementChecked || !reorder || renameQuestion ? true : false
                  }
                  onClick={() => setRenameQuestion(true)}
                >
                  Umbenennen
                </IonButton>
                <IonButton
                  size="medium"
                  shape="round"
                  disabled={
                    !elementChecked || !reorder || renameQuestion ? true : false
                  }
                  onClick={() => questionHandler("deletequestion")}
                >
                  Löschen
                </IonButton>
              </>
            )}
            {elements.length > 1 && (
              <IonButton
                disabled={elementChecked || newQuestion ? true : false}
                onClick={() => {
                  setReorder((e) => (e ? false : true));
                  if (reorder === false) {
                    questionHandler("reorderquestions");
                  }
                }}
                slot="end"
                size="small"
                shape="round"
                fill={reorder ? "outline" : "solid"}
              >
                {/* {!reorder ? "Save Order" : "Re-Order"} */}
                {!reorder ? "Reihenfolge speichern" : "Reihenfolge ändern"}
              </IonButton>
            )}
          </IonItem>
        </IonCol>
      </IonRow>
      {newQuestion ? (
        <IonRow className="ion-padding-start ion-align-items-end">
          <IonItem>
            <NewInput
              credentials={credentials}
              currentPageid={pageid}
              currentSurveyId={currentSurveyId}
              trigger={"newquestion"}
              setNewInput={setNewQuestion}
              setTriggerGet={setTriggerGet}
              placeholdertext={"Name des Elements"}
              // placeholdertext={"Enter New Item"}
            />
          </IonItem>
        </IonRow>
      ) : null}
      <IonRow className="ion-padding">
        <IonList style={{ width: "100%" }}>
          <IonReorderGroup disabled={reorder} onIonItemReorder={doReorder}>
            {elements && elementChecked
              ? elements
                  .filter((e) => e._id === elementChecked._id)
                  .map((e) => (
                    <div key={e._id}>
                      {renameQuestion && (
                        <IonItem>
                          <>
                            <NewInput
                              credentials={credentials}
                              currentElementId={e._id}
                              currentPageid={pageid}
                              currentSurveyId={currentSurveyId}
                              trigger={"renamequestion"}
                              setNewInput={setRenameQuestion}
                              setTriggerGet={setTriggerGet}
                              placeholdertext={e.title}
                            />
                          </>

                          <IonCheckbox
                            checked
                            slot="start"
                            value={e.title}
                            onIonChange={() => {
                              setElementChecked(null);
                              setRenameQuestion(false);
                            }}
                          />
                        </IonItem>
                      )}

                      {!renameQuestion && (
                        <>
                          <IonItem>
                            <div style={{ display: "flex" }}>
                              <div
                                style={{
                                  width: "120px",
                                  background: "#b0c4de7d",
                                  padding: "15px",
                                }}
                              >
                                {e.title}
                              </div>
                              <div
                                style={{
                                  padding: "15px",
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <div
                                  style={
                                    e.question ? null : { fontStyle: "italic" }
                                  }
                                >
                                  {e.question
                                    ? "Frage: " + e.question
                                    : // : "(No details added. Click to get started!)"}
                                      "(Das Objekt ist noch leer. Bitte anklicken / markieren und Aktion wählen)"}
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    paddingTop: "5px",
                                  }}
                                >
                                  {e.type && (
                                    <div
                                      style={{
                                        display: "flex",
                                      }}
                                    >
                                      <IonIcon icon={arrowForwardOutline} />
                                      {"  "}
                                      <div>Typ:</div>
                                      {"  "}
                                      <div>{e.type}</div>
                                    </div>
                                  )}
                                  {e.choices.length > 0 && (
                                    <div
                                      style={{
                                        display: "flex",
                                        paddingLeft: "20px",
                                      }}
                                    >
                                      <IonIcon icon={arrowForwardOutline} />
                                      {"  "}
                                      <div>Möglichkeiten:</div>
                                      {"  "}
                                      {e.choices.map((e, ind, arr) => (
                                        <div key={ind}>
                                          {arr.length - 1 === ind ? e : e + ","}
                                          {"  "}
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                  {e.question && (
                                    <div
                                      style={{
                                        display: "flex",
                                        paddingLeft: "20px",
                                      }}
                                    >
                                      <IonIcon icon={arrowForwardOutline} />
                                      {"  "}
                                      <div>
                                        Pflichtfrage:{" "}
                                        {e.required ? "Ja" : "Nein"}
                                      </div>
                                    </div>
                                  )}
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    paddingTop: "5px",
                                  }}
                                >
                                  <>
                                    <IonIcon icon={arrowForwardOutline} />
                                    {"  "}
                                    <div>Conditional Question:</div>
                                    {"  "}

                                    {e.displaycondition.questionid ? (
                                      <>
                                        <div>
                                          {
                                            elements.filter(
                                              (question) =>
                                                question._id ===
                                                e.displaycondition.questionid
                                            )[0].title
                                          }
                                        </div>

                                        <div
                                          style={{
                                            display: "flex",
                                            paddingLeft: "20px",
                                          }}
                                        >
                                          <IonIcon icon={arrowForwardOutline} />
                                          {"  "}
                                          <div>Answers:</div>
                                          {e.displaycondition.answers.map(
                                            (answers) => (
                                              <div>
                                                {" "}
                                                {answers}
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </>
                                    ) : (
                                      "Nein"
                                    )}
                                  </>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    paddingTop: "5px",
                                    alignItems: "center",
                                  }}
                                >
                                  <>
                                    <IonIcon icon={arrowForwardOutline} />
                                    {"  "}
                                    <div>Excluded Users:</div>
                                    {"  "}

                                    {e.excludedUsers &&
                                    e.excludedUsers.length ? (
                                      // <IonItem lines="none" className="ion-no-padding">
                                      <IonList className="ion-no-padding">
                                        {e.excludedUsers.map((user) => (
                                          <IonChip>{user}</IonChip>
                                        ))}
                                      </IonList>
                                    ) : (
                                      // </IonItem>
                                      "Nein"
                                    )}
                                  </>
                                </div>
                              </div>
                            </div>
                            <IonCheckbox
                              checked
                              slot="start"
                              value={e.title}
                              onIonChange={() => {
                                setElementChecked(null);
                              }}
                            />
                          </IonItem>
                        </>
                      )}
                    </div>
                  ))
              : elements &&
                elements.map((e1) => (
                  <IonItem disabled={newQuestion} key={e1._id}>
                    <IonReorder slot={!reorder ? "end" : "none"} />
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          width: "120px",
                          background: "#b0c4de7d",
                          padding: "15px",
                        }}
                      >
                        {e1.title}
                      </div>
                      <div
                        style={{
                          padding: "15px",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          style={e1.question ? null : { fontStyle: "italic" }}
                        >
                          {e1.question
                            ? `Frage: ${e1.question}`
                            : // : "(No details added. Click to get started!)"}
                              "(Das Objekt ist noch leer. Bitte anklicken / markieren und Aktion wählen)"}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            paddingTop: "5px",
                          }}
                        >
                          {e1.type && (
                            <div
                              style={{
                                display: "flex",
                              }}
                            >
                              <IonIcon icon={arrowForwardOutline} />
                              {"  "}
                              <div>Type:</div>
                              {"  "}
                              <div>{e1.type}</div>
                            </div>
                          )}
                          {e1.choices.length > 0 && (
                            <div
                              style={{
                                display: "flex",
                                paddingLeft: "20px",
                              }}
                            >
                              <IonIcon icon={arrowForwardOutline} />
                              {"  "}
                              <div>Choices:</div>
                              {"  "}
                              {e1.choices.map((e, ind, arr) => (
                                <div key={ind}>
                                  {arr.length - 1 === ind ? e : e + ","}
                                  {"  "}
                                </div>
                              ))}
                            </div>
                          )}
                          {e1.question && (
                            <div
                              style={{
                                display: "flex",
                                paddingLeft: "20px",
                              }}
                            >
                              <IonIcon icon={arrowForwardOutline} />
                              {"  "}
                              <div>
                                Pflichtfrage: {e1.required ? "Ja" : "Nein"}
                              </div>
                            </div>
                          )}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            paddingTop: "5px",
                          }}
                        >
                          <>
                            <IonIcon icon={arrowForwardOutline} />
                            {"  "}
                            <div>Conditional Question:</div>
                            {"  "}

                            {e1.displaycondition.questionid && e1.displaycondition.questionid.title ? (
                              <>
                                <div>
                                  {
                                    elements.filter(
                                      (question) =>
                                        question._id ===
                                        e1.displaycondition.questionid
                                    )[0].title
                                  }
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    paddingLeft: "20px",
                                  }}
                                >
                                  <IonIcon icon={arrowForwardOutline} />
                                  {"  "}
                                  <div>Answers:</div>
                                  {e1.displaycondition.answers.map(
                                    (answers) => (
                                      <div>
                                        {" "}
                                        {answers}
                                      </div>
                                    )
                                  )}
                                </div>
                              </>
                            ) : (
                              "Nein"
                            )}
                          </>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            paddingTop: "5px",
                            alignItems: "center",
                          }}
                        >
                          <>
                            <IonIcon icon={arrowForwardOutline} />
                            {"  "}
                            <div>Excluded Users:</div>
                            {"  "}

                            {e1.excludedUsers && e1.excludedUsers.length ? (
                              // <IonItem lines="none" className="ion-no-padding">
                              <IonList className="ion-no-padding">
                                {e1.excludedUsers.map((user) => (
                                  <IonChip>{user}</IonChip>
                                ))}
                              </IonList>
                            ) : (
                              // </IonItem>
                              "Nein"
                            )}
                          </>
                        </div>
                      </div>
                    </div>

                    {reorder && (
                      <IonCheckbox
                        slot="start"
                        value={e1.title}
                        onIonChange={(e2) => {
                          setElementChecked({
                            _id: e1._id,
                            name: e2.detail.value,
                          });
                          setElementAndPageId({ e1, pageid });
                        }}
                      />
                    )}
                  </IonItem>
                ))}
          </IonReorderGroup>
        </IonList>
      </IonRow>
      <IonRow style={{ height: "20px", borderBottom: "2px solid #2174dc" }} />
    </>
  );
};

export default SurveyPageRenderer;
