import {
  IonLabel,
  IonCheckbox,
  IonItem,
  IonCard,
  IonCardContent,
  IonCol,
  IonFabButton,
  IonIcon,
  IonInput,
  IonButton,
  IonRow,
  IonList,
} from "@ionic/react";
import React, { useState, useEffect } from "react";
import { add } from "ionicons/icons";

const MultipleOptions = ({
  setSubmitQuestion,
  setSelected,
  selected,
  setQuestionDetails,
  elementAndPageId,
}) => {
  const [inputOptions, setInputOptions] = useState(
    elementAndPageId.e1.choices.length > 0 ? elementAndPageId.e1.choices : []
  );
  const [inputValue, setInputValue] = useState(null);
  const [newOption, setNewOption] = useState(null);

  useEffect(() => {
    if (selected === "checkbox") {
      if (inputOptions && inputOptions.length) {
        setSubmitQuestion(true);
        setQuestionDetails({ type: "checkbox", choices: inputOptions });
      } else {
        setSubmitQuestion(false);
      }
    }
  }, [selected, setSubmitQuestion, setQuestionDetails, inputOptions]);

  const optionHandler = (trigger, input) => {
    if (trigger === "add") {
      setInputOptions([...inputOptions, input]);
    }

    if (trigger === "delete") {
      let newArr = [...inputOptions];
      newArr.splice(input, 1);

      setInputOptions(newArr);
    }
    setNewOption(false);
    setInputValue(null);
  };

  return (
    <>
      {!selected ? (
        <IonCol size="6">
          <div style={{ height: "235px", display: "flex" }}>
            <div>
              <IonCheckbox
                checked={selected === "checkbox"}
                value={"checkbox"}
                onIonChange={(e) => {
                  e.detail.checked
                    ? setSelected(e.detail.value)
                    : setSelected(null);
                }}
              />
            </div>
            <IonCard style={{ width: "100%", margin: "0px 10px" }}>
              <IonItem>
                <IonLabel>Mehrfach-Auswahl</IonLabel>
              </IonItem>

              <IonCardContent>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div>Beispiel:</div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      outline: "solid #0000005e 1px",
                      padding: "10px 0px",
                      margin: "10px 0px",
                      height: "125px",
                    }}
                  >
                    <IonList>
                      <IonItem lines="none">
                        <IonLabel>Gut</IonLabel>
                        <IonCheckbox slot="start" />
                      </IonItem>
                      <IonItem lines="none">
                        <IonLabel>Schlecht</IonLabel>
                        <IonCheckbox slot="start" />
                      </IonItem>
                    </IonList>
                  </div>
                </div>
              </IonCardContent>
            </IonCard>
          </div>
        </IonCol>
      ) : selected === "checkbox" ? (
        <>
          <IonCol size="12">
            <div style={{ display: "flex" }}>
              <div>
                <IonCheckbox
                  checked
                  value={"checkbox"}
                  onIonChange={(e) => {
                    e.detail.checked
                      ? setSelected(e.detail.value)
                      : setSelected(null);
                  }}
                />
              </div>
              <IonCard style={{ width: "100%", margin: "0px 10px" }}>
                <IonItem>
                  <IonLabel>Mehrfach-Auswahl</IonLabel>
                </IonItem>

                <IonCardContent>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div>Vorschau:</div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        outline: "solid #0000005e 1px",
                        padding: "10px 0px",
                        margin: "10px 0px",
                      }}
                    >
                      <IonList>
                        {inputOptions.map((e, ind) => (
                          <IonItem key={ind} lines="none">
                            <IonLabel>{e}</IonLabel>
                            <IonCheckbox slot="start" />
                          </IonItem>
                        ))}
                      </IonList>
                    </div>
                  </div>
                </IonCardContent>
              </IonCard>
            </div>
          </IonCol>
          <IonCol size="12">
            <IonRow
              style={{ display: "flex", alignItems: "center", height: "100px" }}
              className="ion-padding"
            >
              <div style={{ paddingRight: "10px" }}>
                <IonFabButton onClick={() => setNewOption(true)}>
                  <IonIcon icon={add} />
                </IonFabButton>
              </div>
              {newOption ? (
                <>
                  <IonItem>
                    <IonInput
                      value={inputValue}
                      placeholder={"Enter New Option"}
                      onIonChange={(e) => setInputValue(e.detail.value)}
                    ></IonInput>
                  </IonItem>
                  <IonButton
                    onClick={() => optionHandler("add", inputValue)}
                    color="primary"
                  >
                    Speichern
                  </IonButton>
                  <IonButton
                    onClick={() => {
                      setNewOption(false);
                      setInputValue(null);
                    }}
                    color="primary"
                  >
                    Abbrechen
                  </IonButton>
                </>
              ) : (
                "Option hinzufügen"
              )}
            </IonRow>
            <IonRow className="ion-padding-start">
              <IonList style={{ width: "100%" }}>
                {inputOptions &&
                  inputOptions.map((e, ind) => (
                    <IonItem key={ind}>
                      <IonLabel>{e}</IonLabel>
                      <IonButton
                        onClick={() => optionHandler("delete", ind)}
                        slot="end"
                      >
                        Löschen
                      </IonButton>
                    </IonItem>
                  ))}
              </IonList>
            </IonRow>
          </IonCol>
        </>
      ) : null}
    </>
  );
};

export default MultipleOptions;
