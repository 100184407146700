import {
  IonCol,
  IonRow,
  IonFabButton,
  IonIcon,
  IonItem,
  IonReorderGroup,
  IonButton,
  IonReorder,
  IonList,
  IonSelectOption,
  IonLabel,
  IonSelect,
} from "@ionic/react";
import React, { useState, useEffect, useCallback } from "react";
import EditPageQUestion from "./EditPageQuestion";
import NewInput from "./NewInput";
import SurveyPageRenderer from "./SurveyPageRenderer";
import { add } from "ionicons/icons";

import "../app.css";

const EditSurvey = ({ surveyListChecked, credentials }) => {
  const [newElementsOrder, setNewElementsOrder] = useState();
  const [newPage, setNewPage] = useState();
  const [pageLanguage, setPageLanguage] = useState('deutsch');
  const [triggerGet, setTriggerGet] = useState(true);
  const [currentSurvey, setCurrentSurvey] = useState(null);
  const [elementAndPageId, setElementAndPageId] = useState(null);
  const [editQuestionMode, setEditQuestionMode] = useState(null);
  const [pageReoderMode, setPageReoderMode] = useState(true);

  console.log("ElementANDpage", elementAndPageId);

  function doSome(e) { console.log(e)}

  // useEffect( () => {console.log(pageLanguage) }, [pageLanguage])

  useEffect(() => {
    currentSurvey && setNewElementsOrder(currentSurvey.pages);
  }, [currentSurvey]);

  const doReorder = (event) => {
    const savedElements = [...newElementsOrder];
    console.log(savedElements);
    let draggedItem = savedElements.splice(event.detail.from, 1)[0];
    savedElements.splice(event.detail.to, 0, draggedItem);
    setNewElementsOrder(savedElements);
    event.detail.complete();
  };

  const getSurvey = useCallback(
    async (trigger) => {
      try {
        const query = await fetch(
          process.env.NODE_ENV === "production"
            ? `https://fb-backend.procuratio.com/editsurvey/`
            : `http://localhost:3000/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              clientid: credentials,
              trigger,
              surveyid: surveyListChecked._id,
              ...(newElementsOrder && { newsurveyorder: newElementsOrder }),
              language: pageLanguage
            }),
          }
        );

        const data = await query.json();
        console.log(data);

        setCurrentSurvey(data);
      } catch (err) {
        console.log(err);
      } finally {
        setTriggerGet(false);
      }
    },
    [
      surveyListChecked,
      credentials,
      setCurrentSurvey,
      setTriggerGet,
      newElementsOrder,
    ]
  );

  useEffect(() => {
    triggerGet && getSurvey("getcurrentsurvey");
  }, [triggerGet, getSurvey]);

  return (
    <>
      <IonRow className="ion-padding">
        <IonCol size="12">Overview: {surveyListChecked.name}</IonCol>
      </IonRow>
      {editQuestionMode ? (
        <EditPageQUestion
          currentPageElements={
            currentSurvey.pages.filter(
              (elem) => elem._id === elementAndPageId.pageid
            )[0]
          }
          setElementAndPageId={setElementAndPageId}
          credentials={credentials}
          elementAndPageId={elementAndPageId}
          currentSurveyId={surveyListChecked._id}
          setTriggerGet={setTriggerGet}
          setEditQuestionMode={setEditQuestionMode}
        />
      ) : (
        <>
          <IonItem lines="none" style={{ width: "100%" }}>

          {/* <IonList style={{ width: "300px" }}>
          <IonItem>
            <IonLabel>Sprache</IonLabel>
            <IonSelect value="deutsch" onIonChange={(e) => doSome(e.target.value)}>
              <IonSelectOption value="englisch" onClick={(e) => doSome(e.target.value)}>Englisch</IonSelectOption>
              <IonSelectOption value="deutsch" onChange={() => setPageLanguage('deutsch')}>Deutsch</IonSelectOption>
            </IonSelect>
          </IonItem>
          </IonList> */}
            <IonButton
              disabled={false}
              onClick={() => {
                setPageReoderMode((e) => (e ? false : true));
                if (pageReoderMode === false) {
                  getSurvey("reordersurvey");
                }
              }}
              slot="end"
              size="small"
              shape="round"
              fill={pageReoderMode ? "outline" : "solid"}
            >
              {!pageReoderMode
                ? "Seite reihenfolge speichern"
                : "Seite reihenfolge ändern"}
            </IonButton>
          </IonItem>
          <IonReorderGroup
            disabled={pageReoderMode}
            onIonItemReorder={(event) => doReorder(event)}
          >
            {currentSurvey &&
              surveyListChecked &&
              currentSurvey.pages.map((e) => (
                <IonItem style={{ width: "100%" }} key={e._id}>
                  <IonReorder style={{ width: "100%" }}>
                    <div>
                      <SurveyPageRenderer
                        credentials={credentials}
                        key={e._id}
                        pagetitle={e.title}
                        elements={e.elements}
                        pageid={e._id}
                        pageconditions={e.pageconditions}
                        currentSurveyId={surveyListChecked._id}
                        setTriggerGet={setTriggerGet}
                        setElementAndPageId={setElementAndPageId}
                        setEditQuestionMode={setEditQuestionMode}
                        currentSurvey={currentSurvey}
                      />
                    </div>
                  </IonReorder>
                </IonItem>
              ))}
          </IonReorderGroup>

          <IonRow
            style={{ display: "flex", alignItems: "center", height: "100px" }}
            className="ion-padding"
          >
            <div style={{ paddingRight: "10px" }}>
              <IonFabButton onClick={() => setNewPage(true)}>
                <IonIcon icon={add} />
              </IonFabButton>
            </div>

            {newPage && surveyListChecked ? (
              <IonItem>
                <NewInput
                  credentials={credentials}
                  trigger={"newpage"}
                  currentSurveyId={surveyListChecked._id}
                  setNewInput={setNewPage}
                  setTriggerGet={setTriggerGet}
                />
              </IonItem>
            ) : (
              "Seite hinzufügen"
            )}
          </IonRow>
        </>
      )}
    </>
  );
};

export default EditSurvey;
