import {
  IonLabel,
  IonCheckbox,
  IonItem,
  IonCard,
  IonCardContent,
  IonCol,
  IonRadioGroup,
  IonRadio,
  IonListHeader,
  IonList,
  IonInput
} from "@ionic/react";
import React, { useState, useEffect } from "react";

import Rating from "@material-ui/lab/Rating";

  
  const RatingModule = ({
  setSubmitQuestion,
  setSelected,
  selected,
  setQuestionDetails,
  elementAndPageId,
}) => {

  console.log("selected", selected)
  console.log("elPageId", elementAndPageId)

  const [ratingSelection, setRatingSelection] = useState(
    elementAndPageId.e1.type ? elementAndPageId.e1.type : null
  );
  const [textRechts, setTextRechts] = useState(
  elementAndPageId.e1.right ? elementAndPageId.e1.right : ""
  );
  const [textLinks, setTextLinks] = useState(
    elementAndPageId.e1.left ? elementAndPageId.e1.left : ""
    );

  useEffect(() => {
    if (selected === "RatingModule") {
      if (ratingSelection) {
        setSubmitQuestion(true);
        setQuestionDetails( { type: ratingSelection,
        left: textLinks,
      right:  textRechts} );
      console.log("SQD", setQuestionDetails.left)      
      }
    } else {
      setSubmitQuestion(false);
    }
  }, [selected, setSubmitQuestion, setQuestionDetails, ratingSelection, textRechts, textLinks]);

  function handleChangeLinks(e) {
    setTextLinks(e)
  }

  function handleChangeRechts(e) {
    setTextRechts(e)
  }

  return (
    <>
      <IonCol size={!selected ? 6 : selected === "RatingModule" ? 12 : null}>
        <div style={{ height: "185px", display: "flex" }}>
          <div>
            <IonCheckbox
              checked={selected === "RatingModule"}
              value={"RatingModule"}
              onIonChange={(e) => {
                e.detail.checked
                  ? setSelected(e.detail.value)
                  : setSelected(null);
              }}
            />
          </div>
          <IonCard style={{ width: "100%", margin: "0px 10px" }}>
            <IonItem>
              <IonLabel>Sterne-Bewertung</IonLabel>
            </IonItem>

            <IonCardContent>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div>
                  {selected === "RatingModule" ? "Vorschau" : "Beispiel"}:
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    outline: "solid #0000005e 1px",
                    padding: "10px 0px",
                    margin: "10px 0px",
                    minHeight: "75px",
                  }}
                >

                  { textLinks !== "" ? <strong>{textLinks}</strong> : '' }
                  <Rating
                    max={
                      !selected
                        ? 5
                        : selected === "RatingModule"
                        ? ratingSelection === "rating10"
                          ? 10
                          : 5
                        : null
                    }
                    name="questionrating"
                    size="large"
                    value={null}
                    precision={1}
                  />
                  { textRechts !== "" ? <span style={{maginLeft: '20px'}}><strong>{textRechts}</strong></span> : '' }

                </div>
              </div>
            </IonCardContent>
          </IonCard>
        </div>
      </IonCol>
      {selected === "RatingModule" && (
        <IonCol size="12">
          <IonList>
            <IonRadioGroup
              value={ratingSelection}
              onIonChange={(e) => setRatingSelection(e.detail.value)}
            >
              <IonListHeader>
                <IonLabel>Bewertungssystem auswählen:</IonLabel>
              </IonListHeader>
              <IonItem>
                <IonLabel>5 Sterne - Bewertung</IonLabel>
                <IonRadio slot="start" value="rating5" />
                <Rating
                  name="5rating"
                  size="medium"
                  value={null}
                  precision={1}
                />
              </IonItem>

              <IonItem>
                <IonLabel>10 Sterne - Bewertung</IonLabel>
                <IonRadio slot="start" value="rating10" />
                <Rating
                  max={10}
                  name="10rating"
                  size="medium"
                  value={null}
                  precision={1}
                />
              </IonItem>



             {/* <IonItem>
                <IonLabel>Nummern</IonLabel>
                <IonRadio slot="start" value="numbers" /> */}
                {/* <Rating
                  max={10}
                  name="10rating"
                  size="medium"
                  value={null}
                  precision={1}
                /> */}
                  {/* 1 - 10
              </IonItem> */}

              {/* <IonItem>
                <IonLabel>Smileys</IonLabel>
                <IonRadio slot="start" value="rating10" />
                <Rating
                  max={10}
                  name="10rating"
                  size="medium"
                  value={null}
                  precision={1}
                />
              </IonItem> */}

            </IonRadioGroup>
          </IonList>
          <IonList>
              <IonListHeader>
                <IonLabel>Sterne benennen:</IonLabel>
              </IonListHeader>
              
              <IonItem lines="none">
               Bezeichnung <ion-badge color="medium" style={{margin: "0px 10px"}}>VOR</ion-badge> den Sternen
               <IonItem stlye={{marginLeft: '20px'}}><IonInput value={textLinks} placeholder="Platzhalter links" onKeyUp={(e) => handleChangeLinks(e.target.value)}></IonInput></IonItem>

              </IonItem>
              <IonItem lines="none">
               Bezeichnung <ion-badge color="medium" style={{margin: "0px 10px"}}>NACH</ion-badge> den Sternen
               <IonItem><IonInput  value={textRechts} placeholder="Platzhalter rechts" onKeyUp={(e) => handleChangeRechts(e.target.value)}></IonInput></IonItem>

              </IonItem>
              </IonList>
        </IonCol>
      )}
    </>
  );
};

export default RatingModule;
