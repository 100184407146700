import {
    IonLabel,
    IonCheckbox,
    IonItem,
    IonCard,
    IonCardContent,
    IonCol,
    IonRadio,
    IonRadioGroup
  } from "@ionic/react";
  import React, { useEffect } from "react";
  
  const Numbers = ({
    setSubmitQuestion,
    setSelected,
    selected,
    setQuestionDetails,
  }) => {
    useEffect(() => {
      if (selected === "nummern") {
        setSubmitQuestion(true);
        setQuestionDetails({ type: "nummern" });
      } else {
        setSubmitQuestion(false);
      }
    }, [selected, setSubmitQuestion, setQuestionDetails]);
  
    return (
      <IonCol size={!selected ? 6 : selected === "nummern" ? 12 : null}>
        <div style={{ height: "185px", display: "flex" }}>
          <div>
            <IonCheckbox
              checked={selected === "nummern"}
              value={"nummern"}
              onIonChange={(e) => {
                e.detail.checked
                  ? setSelected(e.detail.value)
                  : setSelected(null);
              }}
            />
          </div>
          <IonCard style={{ width: "100%", margin: "0px 10px" }}>
            <IonItem>
              <IonLabel>Nummern-Bewertung</IonLabel>
            </IonItem>
  
            <IonCardContent>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div>Beispiel:</div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    outline: "solid #0000005e 1px",
                    padding: "10px 0px",
                    margin: "10px 0px",
                    height: "75px",
                    fontSize:"16px",
                    fontWeight:"bold"
                  }}
                >
                  <IonRadioGroup
                      value={null}
                      onIonChange={(e) => setSelected(e.detail.value)}
                    >
                 <IonRadio slot="start" value="1" style={{marginRight: '3px',marginLeft: '8px'}} />{"1"}
                 <IonRadio slot="start" value="2" style={{marginRight: '3px',marginLeft: '8px'}}  />{"2"}
                 <IonRadio slot="start" value="3"  style={{marginRight: '3px',marginLeft: '8px'}}   />{"3"}
                 <IonRadio slot="start" value="4"  style={{marginRight: '3px',marginLeft: '8px'}}   />{"4"}
                 <IonRadio slot="start" value="5"  style={{marginRight: '3px',marginLeft: '8px'}}   />{"5"}
                 <IonRadio slot="start" value="6" style={{marginRight: '3px',marginLeft: '8px'}}   />{"6"}
                 <IonRadio slot="start" value="7" style={{marginRight: '3px',marginLeft: '8px'}}   />{"7"}
                 <IonRadio slot="start" value="8" style={{marginRight: '3px',marginLeft: '8px'}}   />{"8"}
                 <IonRadio slot="start" value="9" style={{marginRight: '3px',marginLeft: '8px'}}   />{"9"}
                 <IonRadio slot="start" value="10" style={{marginRight: '3px',marginLeft: '8px'}}   />{"10"}

                  </IonRadioGroup>
                </div>
              </div>
            </IonCardContent>
          </IonCard>
        </div>
      </IonCol>
    );
  };
  
  export default Numbers;
  