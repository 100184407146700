import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonChip,
} from "@ionic/react";

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  fastFoodOutline,
  fastFoodSharp,
  gitPullRequestOutline,
  gitPullRequestSharp,
  buildOutline,
  buildSharp,
  logOutOutline,
  logOutSharp,
  peopleOutline,
  peopleSharp,
} from "ionicons/icons";
import "./Menu.css";

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
  cred: number;
}

const appPages: AppPage[] = [
  // {
  //   title: "Admin Login",
  //   url: "/page/Login",
  //   iosIcon: fastFoodOutline,
  //   mdIcon: fastFoodSharp,
  // },
  // {
  //   title: "Speiseplan Tool",
  //   url: "/page/Menu",
  //   iosIcon: fastFoodOutline,
  //   mdIcon: fastFoodSharp,
  //   cred: 1,
  // },
  {
    title: "Umfrageverwaltung",
    url: "/page/Survey",
    iosIcon: gitPullRequestOutline,
    mdIcon: gitPullRequestSharp,
    cred: 1,
  },
  {
    title: "Benutzerverwaltung",
    url: "/page/AdminPanel",
    iosIcon: peopleOutline,
    mdIcon: peopleSharp,
    cred: 2,
  },
  {
    title: "Statistische Auswertung",
    url: "/page/Statistics",
    iosIcon: buildOutline,
    mdIcon: buildSharp,
    cred: 0,
  },
  {
    title: "Abmelden",
    url: "/page/Logout",
    iosIcon: logOutOutline,
    mdIcon: logOutSharp,
    cred: 0,
  },
];

const Menu: React.FC = (props) => {
  const location = useLocation();

  const [credData, setCredData] = useState("");

  useEffect(() => {
    if (props) {
      setCredData(props["credentials"]);
    }
  }, []);

  useEffect(() => {
    //  console.log(credData['logo64'])
  }, [credData]);

  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        {credData && (
          <IonList id="inbox-list">
            <IonListHeader>
              <img src={credData["logo64"]} alt="" style={{ width: "80%", height: "auto"}} />
            </IonListHeader>
            <div className="credMail ion-text-end">
              <IonChip>{credData["email"]}</IonChip>
            </div>
            {appPages.map((appPage, index) => {
              if (credData["privilages"] >= appPage.cred) {
                return (
                  <IonMenuToggle key={index} autoHide={false}>
                    <IonItem
                      className={
                        location.pathname === appPage.url ? "selected" : ""
                      }
                      routerLink={appPage.url}
                      routerDirection="none"
                      lines="none"
                      detail={false}
                    >
                      <IonIcon
                        slot="start"
                        ios={appPage.iosIcon}
                        md={appPage.mdIcon}
                      />
                      <IonLabel>{appPage.title}</IonLabel>
                    </IonItem>
                  </IonMenuToggle>
                );
              }
            })}
          </IonList>
        )}
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
