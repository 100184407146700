import {
  IonLabel,
  IonCheckbox,
  IonItem,
  IonCard,
  IonCardContent,
  IonCol,
} from "@ionic/react";
import React, { useEffect } from "react";

const NoResponse = ({
  setSubmitQuestion,
  setSelected,
  selected,
  setQuestionDetails,
}) => {
  useEffect(() => {
    if (selected === "textblock") {
      setSubmitQuestion(true);
      setQuestionDetails({ type: "textblock" });
    } else {
      setSubmitQuestion(false);
    }
  }, [selected, setSubmitQuestion, setQuestionDetails]);

  return (
    <IonCol size={!selected ? 6 : selected === "textblock" ? 12 : null}>
      <div style={{ height: "235px", display: "flex" }}>
        <div>
          <IonCheckbox
            checked={selected === "textblock"}
            value={"textblock"}
            onIonChange={(e) => {
              e.detail.checked
                ? setSelected(e.detail.value)
                : setSelected(null);
            }}
          />
        </div>
        <IonCard style={{ width: "100%", margin: "0px 10px" }}>
          <IonItem>
            <IonLabel>Informationsseite OHNE Auswahl</IonLabel>
          </IonItem>

          <IonCardContent>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div>Beispiel:</div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  outline: "solid #0000005e 1px",
                  padding: "10px 0px",
                  margin: "10px 0px",
                  height: "125px",
                }}
              >
                (Keine Auswahlmöglichkeiten)
              </div>
            </div>
          </IonCardContent>
        </IonCard>
      </div>
    </IonCol>
  );
};

export default NoResponse;
