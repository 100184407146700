import Menu from "./components/Menu";
import MenuAdmin from "./pages/MenuAdmin";
import Login from "./pages/Login";
import AdminPanel from "./pages/AdminPanel";
import SurveyCreator from "./pages/SurveyCreator";
import StatisticsOverview from "./pages/StatisticsOverview";
import React, { useState, useEffect } from "react";
import "./app.css";
import { IonApp, IonSplitPane, IonContent } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Redirect, Route } from "react-router-dom";
import Cookies from "universal-cookie";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";

const App = () => {
  const [credentials, setCredentials] = useState();
  const [authenticate, setAuthenticate] = useState(true);

  useEffect(() => {

    console.log("ENV", process.env.NODE_ENV);
    if (authenticate) {
      const cookies = new Cookies();
      if (cookies.get("admincookie")) {
        fetch(
          process.env.NODE_ENV === "production"
            ? `https://fb-backend.procuratio.com/admin/getadminuserdata/`
            : `http://localhost:3000/`,
            

          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              admincookie: cookies.get("admincookie"),
            }),
          }
        )
          .then((response) => response.json())
          .then((result) => {
            if (result.success) {
              setCredentials(result.newJson);
            }
          })
          .catch((error) => console.log("error", error));
      }
    }
    setAuthenticate(false);
  }, [authenticate]);

  // const routerRef = useRef(null);

  return (
    <IonApp>
      <IonReactRouter>
        {credentials ? (
          <>
            <IonSplitPane className="splitpanecss" when={"md"} contentId="main">
              <Menu credentials={credentials} />
              <IonContent id="main">
                <Redirect to="/page/Statistics/" />
                <Route
                  path="/page/Menu/"
                  render={() => <MenuAdmin credentials={credentials} />}
                  exact
                />
                <Route
                  path="/page/Survey/"
                  render={() => <SurveyCreator credentials={credentials} />}
                  exact
                />
                <Route
                  path="/page/Statistics/"
                  render={() => (
                    <StatisticsOverview credentials={credentials} />
                  )}
                  exact
                />
                <Route
                  path="/page/AdminPanel/"
                  render={() => <AdminPanel />}
                  exact
                />

                <Route
                  path="/page/Logout/"
                  render={() => {
                    const cookies = new Cookies();
                    cookies.remove("admincookie", { path: "/" });
                    setCredentials(null);
                  }}
                />
              </IonContent>
            </IonSplitPane>
          </>
        ) : (
          <>
            <IonContent id="main">
              <Route
                path="/page/Login/"
                render={() => <Login setAuthenticate={setAuthenticate} />}
                exact
              />
            </IonContent>
            <Redirect to="/page/Login" exact />
          </>
        )}
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
